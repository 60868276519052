// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD6QSzV9nH4oKBG5MBPBA6hiKKXK9W8pfA",
  authDomain: "catechismonline-f295f.firebaseapp.com",
  projectId: "catechismonline-f295f",
  storageBucket: "catechismonline-f295f.appspot.com",
  messagingSenderId: "306135146179",
  appId: "1:306135146179:web:ee3a9b2da96478e75a4c76",
  measurementId: "G-S65BXJ3J0V"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app); // Inizializza ed esporta storage
