// src/pages/AdminDashboard.js

import React, { useEffect, useState } from 'react';
import { db, auth } from '../firebase';
import {
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  writeBatch,
} from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
  Grid,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Card,
  CardContent,
  CardActions,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StudentEditForm from '../components/StudentEditForm';
import StudentDetails from '../components/StudentDetails';
import CatechistEditForm from '../components/CatechistEditForm';
import CatechistDetails from '../components/CatechistDetails';
import Sidebar from '../components/Sidebar';
import { FaBars } from 'react-icons/fa';
import moment from 'moment';
import 'moment/locale/it';
import '../styles/AdminDashboard.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

function AdminDashboard() {
  const [catechists, setCatechists] = useState([]);
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editStudentOpen, setEditStudentOpen] = useState(false);
  const [detailsStudentOpen, setDetailsStudentOpen] = useState(false);
  const [editCatechistOpen, setEditCatechistOpen] = useState(false);
  const [detailsCatechistOpen, setDetailsCatechistOpen] = useState(false);
  const [selectedCatechist, setSelectedCatechist] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);

  // Stato per la conferma di eliminazione del catechista
  const [deleteCatechistConfirmOpen, setDeleteCatechistConfirmOpen] = useState(false);
  const [catechistToDelete, setCatechistToDelete] = useState(null);

  // Stato per la selezione multipla degli studenti
  const [selectedStudentIds, setSelectedStudentIds] = useState([]);
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [targetCatechistId, setTargetCatechistId] = useState('');

  // Stato per Snackbar
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const navigate = useNavigate();
  const adminEmail = 'diariodibordo@redentoremonserrato.it';

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged(async (user) => {
      if (!user) {
        navigate('/login');
      } else {
        if (user.email.toLowerCase() !== adminEmail) {
          alert('Accesso negato. Solo l\'amministratore può accedere a questa pagina.');
          navigate('/dashboard');
        } else {
          fetchCatechists();
          fetchStudents();
        }
      }
    });

    return () => {
      unsubscribeAuth();
    };
  }, [navigate, adminEmail]);

  const fetchCatechists = async () => {
    try {
      const usersCollection = collection(db, 'users');
      const unsubscribeCatechists = onSnapshot(usersCollection, (snapshot) => {
        const catechistsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCatechists(catechistsList);
      });

      return () => {
        unsubscribeCatechists();
      };
    } catch (error) {
      console.error('Errore durante il recupero dei catechisti:', error);
    }
  };

  const fetchStudents = async () => {
    try {
      const studentsCollection = collection(db, 'students');
      const unsubscribeStudents = onSnapshot(studentsCollection, (snapshot) => {
        const studentsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStudents(studentsList);
      });

      return () => {
        unsubscribeStudents();
      };
    } catch (error) {
      console.error('Errore durante il recupero degli studenti:', error);
    }
  };

  // Funzione per gestire l'eliminazione del catechista con conferma
  const handleDeleteCatechist = (catechist) => {
    setCatechistToDelete(catechist);
    setDeleteCatechistConfirmOpen(true);
  };

  const confirmDeleteCatechist = async () => {
    try {
      await deleteDoc(doc(db, 'users', catechistToDelete.id));
      alert('Catechista eliminato con successo.');
      setDeleteCatechistConfirmOpen(false);
      setCatechistToDelete(null);
    } catch (error) {
      console.error('Errore durante l\'eliminazione del catechista:', error);
      alert('Si è verificato un errore durante l\'eliminazione del catechista.');
    }
  };

  // Funzioni per gestire gli studenti
  const handleEditStudent = (student) => {
    setSelectedStudent(student);
    setEditStudentOpen(true);
  };

  const handleDetailsStudent = (student) => {
    setSelectedStudent(student);
    setDetailsStudentOpen(true);
  };

  const handleDeleteStudent = (student) => {
    setStudentToDelete(student);
    setDeleteConfirmOpen(true);
  };

  const confirmDeleteStudent = async () => {
    try {
      await deleteDoc(doc(db, 'students', studentToDelete.id));
      alert('Studente eliminato con successo.');
      setDeleteConfirmOpen(false);
      setStudentToDelete(null);
    } catch (error) {
      console.error('Errore durante l\'eliminazione dello studente:', error);
      alert('Si è verificato un errore durante l\'eliminazione dello studente.');
    }
  };

  // Funzioni per gestire i catechisti
  const handleEditCatechist = (catechist) => {
    setSelectedCatechist(catechist);
    setEditCatechistOpen(true);
  };

  const handleDetailsCatechist = (catechist) => {
    setSelectedCatechist(catechist);
    setDetailsCatechistOpen(true);
  };

  // Funzione per esportare i dati degli studenti in Excel
  const exportStudentsToExcel = () => {
    const workbook = XLSX.utils.book_new();

    // Per ogni catechista, crea un foglio con i suoi studenti
    catechists.forEach((catechist) => {
      const userStudents = students.filter((student) => student.userId === catechist.id);

      const worksheetData = userStudents.map((student) => ({
        Nome: student.nome,
        Cognome: student.cognome,
        'Data di Nascita': student.dataNascita
          ? moment(student.dataNascita.toDate()).format('DD/MM/YYYY')
          : '',
        Indirizzo: student.indirizzo || '',
        'Telefono Padre': student.telefonoPadre || '',
        'Telefono Madre': student.telefonoMadre || '',
        Note: student.note || '',
        Presente: student.present ? 'Sì' : 'No',
      }));

      const worksheet = XLSX.utils.json_to_sheet(worksheetData);

      let catechistName = catechist.nome
        ? `${catechist.nome} ${catechist.cognome}`
        : catechist.email;

      // Assicurati che il nome del foglio non superi i 31 caratteri
      if (catechistName.length > 31) {
        catechistName = catechistName.substring(0, 28) + '...';
      }

      XLSX.utils.book_append_sheet(workbook, worksheet, catechistName);
    });

    // Crea un foglio riepilogativo
    const summaryData = students.map((student) => {
      const catechist = catechists.find((c) => c.id === student.userId);
      const catechistName = catechist
        ? catechist.nome
          ? `${catechist.nome} ${catechist.cognome}`
          : catechist.email
        : 'N/A';

      return {
        Catechista: catechistName,
        Nome: student.nome,
        Cognome: student.cognome,
        'Data di Nascita': student.dataNascita
          ? moment(student.dataNascita.toDate()).format('DD/MM/YYYY')
          : '',
        Indirizzo: student.indirizzo || '',
        'Telefono Padre': student.telefonoPadre || '',
        'Telefono Madre': student.telefonoMadre || '',
        Note: student.note || '',
        Presente: student.present ? 'Sì' : 'No',
      };
    });

    const summarySheet = XLSX.utils.json_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(workbook, summarySheet, 'Riepilogo');

    // Imposta il titolo del report con la data
    const reportTitle = `Report ${moment().format('DD-MM-YYYY')}`;

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const dataBlob = new Blob([excelBuffer], {
      type: 'application/octet-stream',
    });
    saveAs(dataBlob, `${reportTitle}.xlsx`);
  };

  // Funzione per generare statistiche
  const generateStatistics = () => {
    const stats = catechists.map((catechist) => {
      const userStudents = students.filter((student) => student.userId === catechist.id);
      const totalStudents = userStudents.length;
      const totalPresent = userStudents.filter((s) => s.present).length || 0;
      const totalAbsent = totalStudents - totalPresent;
      const presencePercentage = totalStudents ? ((totalPresent / totalStudents) * 100).toFixed(2) : 0;
      const absencePercentage = totalStudents ? ((totalAbsent / totalStudents) * 100).toFixed(2) : 0;

      return {
        nome: catechist.nome || '',
        cognome: catechist.cognome || '',
        totalStudents,
        totalPresent,
        totalAbsent,
        presencePercentage,
        absencePercentage,
      };
    });

    return stats;
  };

  // Funzione per esportare le statistiche in PDF
  const exportStatisticsToPDF = () => {
    const stats = generateStatistics();

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text('Statistiche Studenti', 14, 22);

    autoTable(doc, {
      startY: 30,
      head: [['Catechista', 'Numero Studenti', 'Presenti (%)', 'Assenti (%)']],
      body: stats.map((stat) => [
        `${stat.nome} ${stat.cognome}`,
        stat.totalStudents,
        stat.presencePercentage + '%',
        stat.absencePercentage + '%',
      ]),
    });

    doc.save('Statistiche_Studenti.pdf');
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'N/A';
    if (timestamp instanceof Date) {
      return moment(timestamp).format('DD/MM/YYYY HH:mm');
    } else if (timestamp.toDate) {
      return moment(timestamp.toDate()).format('DD/MM/YYYY HH:mm');
    } else if (timestamp.seconds) {
      return moment(new Date(timestamp.seconds * 1000)).format('DD/MM/YYYY HH:mm');
    } else if (typeof timestamp === 'string') {
      return moment(new Date(timestamp)).format('DD/MM/YYYY HH:mm');
    } else {
      return 'N/A';
    }
  };

  const currentDateFormatted = moment().format('dddd, D MMMM YYYY');

  // Funzione per gestire la selezione di tutti gli studenti
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const allStudentIds = students.filter(student => student.userId === selectedUser).map(student => student.id);
      setSelectedStudentIds(allStudentIds);
    } else {
      setSelectedStudentIds([]);
    }
  };

  // Funzione per gestire la selezione di un singolo studente
  const handleSelectOne = (event, id) => {
    if (event.target.checked) {
      setSelectedStudentIds(prev => [...prev, id]);
    } else {
      setSelectedStudentIds(prev => prev.filter(studentId => studentId !== id));
    }
  };

  // Funzione per aprire il dialog di spostamento
  const handleOpenMoveDialog = () => {
    setMoveDialogOpen(true);
  };

  // Funzione per chiudere il dialog di spostamento
  const handleCloseMoveDialog = () => {
    setMoveDialogOpen(false);
    setTargetCatechistId('');
  };

  // Funzione per spostare gli studenti selezionati
  const handleMoveStudents = async () => {
    if (!targetCatechistId) {
      setSnackbar({
        open: true,
        message: 'Per favore, seleziona un catechista di destinazione.',
        severity: 'error',
      });
      return;
    }

    try {
      const batch = writeBatch(db);
      const targetUserId = targetCatechistId;

      selectedStudentIds.forEach((studentId) => {
        const studentRef = doc(db, 'students', studentId);
        batch.update(studentRef, { userId: targetUserId });
      });

      await batch.commit();

      setSnackbar({
        open: true,
        message: 'Studenti spostati con successo.',
        severity: 'success',
      });

      // Resetta la selezione e chiudi il dialog
      setSelectedStudentIds([]);
      handleCloseMoveDialog();
    } catch (error) {
      console.error('Errore durante lo spostamento degli studenti:', error);
      setSnackbar({
        open: true,
        message: 'Si è verificato un errore durante lo spostamento degli studenti.',
        severity: 'error',
      });
    }
  };

  return (
    <div className="dashboard-container">
      <Sidebar
        isOpen={isSidebarOpen}
        toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
      />
      <div className="dashboard-content">
        <AppBar position="fixed" color="primary" className="app-bar">
          <Toolbar>
            {/* Pulsante per aprire la Sidebar */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              className="menu-button"
            >
              <FaBars />
            </IconButton>
            <Typography variant="h6" className="app-title">
              Admin Dashboard
            </Typography>
            <div className="current-date-container">
              <Typography variant="subtitle1" className="current-date">
                {currentDateFormatted}
              </Typography>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Spazio per compensare l'AppBar fissa */}

        {/* Contenuto principale */}
        <Container maxWidth="lg" className="dashboard-container-content">
          <Grid container spacing={4}>
            {/* Lista dei catechisti registrati con Card */}
            <Grid item xs={12}>
              <Typography variant="h6" className="section-title">
                Catechisti Registrati
              </Typography>
              <Grid container spacing={2}>
                {catechists.map((catechist) => (
                  <Grid item xs={12} sm={6} md={4} key={catechist.id}>
                    <Card className="user-card">
                      <CardContent>
                        <div
                          className="catechist-avatar-container"
                          onClick={() => handleDetailsCatechist(catechist)}
                          style={{ cursor: 'pointer' }}
                        >
                          <Avatar
                            src={catechist.photoURL || '/icons/default-avatar.png'}
                            alt={`${catechist.nome} ${catechist.cognome}`}
                            className="catechist-avatar"
                          />
                        </div>
                        <Typography
                          variant="h6"
                          onClick={() => handleDetailsCatechist(catechist)}
                          style={{ cursor: 'pointer' }}
                          className="catechist-name"
                        >
                          {catechist.nome} {catechist.cognome}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          {catechist.email}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Data di Registrazione: {formatTimestamp(catechist.creationTime)}
                        </Typography>
                        <Typography variant="body2" color="textSecondary">
                          Ultimo Accesso: {formatTimestamp(catechist.lastSignInTime)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => handleEditCatechist(catechist)}
                        >
                          Modifica Catechista
                        </Button>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleDeleteCatechist(catechist)}
                        >
                          Elimina Catechista
                        </Button>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>

            {/* Selezione del catechista per visualizzare gli studenti */}
            <Grid item xs={12}>
              <Paper className="paper">
                <Typography variant="h6" className="section-title">
                  Studenti per Catechista
                </Typography>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="select-user-label">Seleziona Catechista</InputLabel>
                  <Select
                    labelId="select-user-label"
                    value={selectedUser}
                    onChange={(e) => {
                      setSelectedUser(e.target.value);
                      setSelectedStudentIds([]); // Resetta la selezione quando si cambia catechista
                    }}
                    label="Seleziona Catechista"
                  >
                    {catechists.map((catechist) => (
                      <MenuItem value={catechist.id} key={catechist.id}>
                        {catechist.nome} {catechist.cognome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {selectedUser && (
                  <>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox">
                            <Checkbox
                              indeterminate={
                                selectedStudentIds.length > 0 &&
                                selectedStudentIds.length < students.filter(student => student.userId === selectedUser).length
                              }
                              checked={
                                students.filter(student => student.userId === selectedUser).length > 0 &&
                                selectedStudentIds.length === students.filter(student => student.userId === selectedUser).length
                              }
                              onChange={handleSelectAll}
                              inputProps={{ 'aria-label': 'select all students' }}
                            />
                          </TableCell>
                          <TableCell>Nome</TableCell>
                          <TableCell>Cognome</TableCell>
                          <TableCell>Azioni</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {students
                          .filter((student) => student.userId === selectedUser)
                          .map((student) => (
                            <TableRow key={student.id} hover>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={selectedStudentIds.includes(student.id)}
                                  onChange={(e) => handleSelectOne(e, student.id)}
                                  inputProps={{ 'aria-labelledby': `select-student-${student.id}` }}
                                />
                              </TableCell>
                              <TableCell>{student.nome}</TableCell>
                              <TableCell>{student.cognome}</TableCell>
                              <TableCell>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => handleDetailsStudent(student)}
                                  size="small"
                                  className="action-button"
                                >
                                  Dettagli
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => handleEditStudent(student)}
                                  size="small"
                                  className="action-button"
                                >
                                  Modifica
                                </Button>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => handleDeleteStudent(student)}
                                  size="small"
                                  className="action-button"
                                >
                                  Elimina
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {/* Pulsanti per l'azione */}
                    <Grid container spacing={2} alignItems="center" style={{ marginTop: '16px' }}>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenMoveDialog}
                          disabled={selectedStudentIds.length === 0}
                        >
                          Sposta Selezionati
                        </Button>
                      </Grid>
                      <Grid item xs={12} sm={6} className="export-button-container">
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={exportStudentsToExcel}
                          className="export-button"
                        >
                          Esporta Studenti in Excel
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Paper>
            </Grid>

            {/* Sezione per le statistiche */}
            <Grid item xs={12}>
              <Paper className="paper">
                <Typography variant="h6" className="section-title">
                  Statistiche
                </Typography>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Catechista</TableCell>
                      <TableCell>Numero di Studenti</TableCell>
                      <TableCell>Presenti (%)</TableCell>
                      <TableCell>Assenti (%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {generateStatistics().map((stat, index) => (
                      <TableRow key={index}>
                        <TableCell>{stat.nome} {stat.cognome}</TableCell>
                        <TableCell>{stat.totalStudents}</TableCell>
                        <TableCell>{stat.presencePercentage}%</TableCell>
                        <TableCell>{stat.absencePercentage}%</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <div className="export-button-container">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={exportStatisticsToPDF}
                    className="export-button"
                  >
                    Esporta Statistiche in PDF
                  </Button>
                </div>
              </Paper>
            </Grid>

            {/* Pulsante per esportare tutti gli studenti in Excel */}
            <Grid
              item
              xs={12}
              style={{
                textAlign: 'center',
                marginTop: '20px',
                marginBottom: '20px',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={exportStudentsToExcel}
              >
                Esporta Tutti gli Studenti in Excel
              </Button>
            </Grid>
          </Grid>
        </Container>

        {/* Finestre Modali per modificare e visualizzare gli studenti */}
        {editStudentOpen && selectedStudent && (
          <StudentEditForm
            student={selectedStudent}
            onClose={() => setEditStudentOpen(false)}
            open={editStudentOpen}
          />
        )}
        {detailsStudentOpen && selectedStudent && (
          <StudentDetails
            student={selectedStudent}
            onClose={() => setDetailsStudentOpen(false)}
            open={detailsStudentOpen}
          />
        )}

        {/* Finestre Modali per modificare e visualizzare i catechisti */}
        {editCatechistOpen && selectedCatechist && (
          <CatechistEditForm
            catechist={selectedCatechist}
            onClose={() => setEditCatechistOpen(false)}
            open={editCatechistOpen}
          />
        )}
        {detailsCatechistOpen && selectedCatechist && (
          <CatechistDetails
            catechist={selectedCatechist}
            onClose={() => setDetailsCatechistOpen(false)}
            open={detailsCatechistOpen}
          />
        )}

        {/* Dialog di conferma eliminazione studente */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
        >
          <DialogTitle>Conferma Eliminazione</DialogTitle>
          <DialogContent>
            Sei sicuro di voler eliminare questo studente?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteConfirmOpen(false)}>
              Annulla
            </Button>
            <Button color="error" onClick={confirmDeleteStudent}>
              Elimina
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog di conferma eliminazione catechista */}
        <Dialog
          open={deleteCatechistConfirmOpen}
          onClose={() => setDeleteCatechistConfirmOpen(false)}
        >
          <DialogTitle>Conferma Eliminazione</DialogTitle>
          <DialogContent>
            Sei sicuro di voler eliminare questo catechista? Questa azione è irreversibile.
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteCatechistConfirmOpen(false)}>
              Annulla
            </Button>
            <Button color="error" onClick={confirmDeleteCatechist}>
              Elimina
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog per spostare studenti */}
        <Dialog
          open={moveDialogOpen}
          onClose={handleCloseMoveDialog}
        >
          <DialogTitle>Sposta Studenti Selezionati</DialogTitle>
          <DialogContent>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="select-target-catechist-label">Seleziona Catechista di Destinazione</InputLabel>
              <Select
                labelId="select-target-catechist-label"
                value={targetCatechistId}
                onChange={(e) => setTargetCatechistId(e.target.value)}
                label="Seleziona Catechista di Destinazione"
              >
                {catechists
                  .filter(catechist => catechist.id !== selectedUser)
                  .map((catechist) => (
                    <MenuItem value={catechist.id} key={catechist.id}>
                      {catechist.nome} {catechist.cognome}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseMoveDialog}>
              Annulla
            </Button>
            <Button color="primary" onClick={handleMoveStudents}>
              Sposta
            </Button>
          </DialogActions>
        </Dialog>

        {/* Snackbar per notifiche */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </div>
    </div>
  );
}

export default AdminDashboard;
