// src/components/StudentForm.js

import React, { useState } from 'react';
import { db, auth, storage } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Box,
  Grid,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/Form.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'auto',
};

function StudentForm({ onClose, open, onAddStudent }) {
  const [nome, setNome] = useState('');
  const [cognome, setCognome] = useState('');
  const [dataNascita, setDataNascita] = useState(new Date());
  const [indirizzo, setIndirizzo] = useState('');
  const [telefonoPadre, setTelefonoPadre] = useState('');
  const [telefonoMadre, setTelefonoMadre] = useState('');
  const [note, setNote] = useState('');
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null);
  const [present, setPresent] = useState(false);

  const handlePhotoChange = (e) => {
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setPhoto(selectedFile);
      setPhotoPreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleSubmit = async () => {
    const currentUser = auth.currentUser;
    console.log('Utente corrente:', currentUser);
    if (!currentUser) {
      alert('Utente non autenticato.');
      return;
    }

    if (!nome || !cognome || !dataNascita || !indirizzo) {
      alert('Per favore, compila tutti i campi obbligatori.');
      return;
    }

    let photoURL = '';
    if (photo) {
      const sanitizedFileName = photo.name.replace(/\s+/g, '_').replace(/[()]/g, '');
      const photoRef = ref(storage, `students/${Date.now()}_${sanitizedFileName}`);
      try {
        await uploadBytes(photoRef, photo);
        photoURL = await getDownloadURL(photoRef);
      } catch (error) {
        console.error('Errore durante l\'upload della foto:', error);
        alert('Si è verificato un errore durante l\'upload della foto.');
        return;
      }
    }

    const newStudent = {
      nome,
      cognome,
      dataNascita, // Memorizza come oggetto Date
      indirizzo,
      telefonoPadre,
      telefonoMadre,
      note,
      photoURL,
      present,
      userId: currentUser.uid, // Associa lo studente all'utente corrente
    };

    try {
      const docRef = await addDoc(collection(db, 'students'), newStudent);
      const addedStudent = { id: docRef.id, ...newStudent };
      // Chiama la funzione per aggiungere l'evento Compleanno
      if (onAddStudent) {
        onAddStudent(addedStudent);
      }
      // Resetta gli stati
      setNome('');
      setCognome('');
      setDataNascita(new Date());
      setIndirizzo('');
      setTelefonoPadre('');
      setTelefonoMadre('');
      setNote('');
      setPhoto(null);
      setPhotoPreview(null);
      setPresent(false);
      onClose();
    } catch (error) {
      console.error('Errore durante l\'aggiunta dello studente:', error);
      alert('Si è verificato un errore durante l\'aggiunta dello studente.');
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="add-student-modal-title">
      <Box sx={modalStyle}>
        <div className="form-container">
          <h2>Aggiungi Studente</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Cognome"
                value={cognome}
                onChange={(e) => setCognome(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                selected={dataNascita}
                onChange={(date) => setDataNascita(date)}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField
                    label="Data di Nascita"
                    fullWidth
                    required
                  />
                }
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Indirizzo di Residenza"
                value={indirizzo}
                onChange={(e) => setIndirizzo(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Numero di Telefono del Padre"
                value={telefonoPadre}
                onChange={(e) => setTelefonoPadre(e.target.value)}
                fullWidth
                type="tel"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Numero di Telefono della Madre"
                value={telefonoMadre}
                onChange={(e) => setTelefonoMadre(e.target.value)}
                fullWidth
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={present}
                    onChange={(e) => setPresent(e.target.checked)}
                    color="primary"
                  />
                }
                label="Presente"
              />
            </Grid>
            <Grid item xs={12}>
              <div className="photo-upload">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  style={{ display: 'none' }}
                  id="photo-upload-input"
                />
                <label htmlFor="photo-upload-input">
                  <Button variant="contained" component="span">
                    Carica Foto
                  </Button>
                </label>
                {photoPreview && (
                  <img
                    src={photoPreview}
                    alt="Anteprima Foto"
                    className="photo-preview"
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Aggiungi
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onClose}>
                  Annulla
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}

export default StudentForm;
