// src/pages/Library.js

import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  CardActions,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import {
  FaHome,
  FaBars,
  FaGlobe,
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaPinterestP,
  FaCalendarAlt,
} from 'react-icons/fa';
import '../styles/Library.css';
import moment from 'moment';
import 'moment/locale/it';

function Library() {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Funzione per aprire/chiudere la Sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Funzione per navigare alla Dashboard
  const navigateToDashboard = () => {
    navigate('/Dashboard');
    toggleSidebar(); // Chiude la Sidebar dopo la navigazione
  };

  const currentDateFormatted = moment().format('dddd, D MMMM YYYY');

  // Array di documenti con link di Google Drive
  const documents = [
    {
      title: 'Bibbia',
      description: 'Clicca e Consulta',
      image: 'https://i.postimg.cc/XYk9NTSg/4-C11-C232-1-D5-A-4649-9-B8-F-A60-CD4-F3-FEAB.jpg',
      link: 'https://drive.google.com/file/d/1AhCCETJN84wmqsxN1s_Y7h3SEhXhtS19/view?usp=drivesdk',
    },
    {
      title: 'Vangelo',
      description: 'Clicca e Consulta',
      image: 'https://i.postimg.cc/zX5BD3J9/9-E035-B4-B-E42-A-4-C40-8752-66831-DABEED5.jpg',
      link: 'https://drive.google.com/file/d/1W5UWYs6I0eduufqjsXdQebxVGkcLd8fU/view?usp=drivesdk',
    },
    {
      title: 'Messa del Giorno',
      description: 'Clicca e Consulta',
      image: 'https://i.postimg.cc/wjLsfnjp/E2-FBF62-F-A628-470-E-90-F0-7-A0-EE26523-B2.jpg',
      link: 'https://www.chiesacattolica.it/liturgia-del-giorno/',
    },
    {
      title: 'Foglietto Parrocchiale',
      description: 'Clicca e Consulta',
      image: 'https://i.postimg.cc/YS5zPQ14/IMG-8705.jpg',
      link: 'https://www.redentoremonserrato.it/foglietto_0410.html',
    },
  ];

  return (
    <div className="library-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`library-content ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        {/* AppBar */}
        <AppBar position="fixed" color="primary" className="app-bar">
          <Toolbar>
            {/* Pulsante per aprire la Sidebar */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
              className="menu-button"
            >
              <FaBars />
            </IconButton>

            {/* Titolo della Libreria */}
            <Typography variant="h6" className="app-title">
              Libreria
            </Typography>

            {/* Spazio per allineare gli elementi */}
            <div style={{ flexGrow: 1 }} />

            {/* Contenitore per la Data */}
            <Typography variant="subtitle1" className="current-date">
              {currentDateFormatted}
            </Typography>

            {/* Icona della Casa */}
            <Tooltip title="Home">
              <IconButton
                edge="end"
                color="inherit"
                aria-label="home"
                onClick={navigateToDashboard}
                className="home-icon-button"
              >
                <FaHome />
              </IconButton>
            </Tooltip>

            {/* Icona del Calendario */}
            <Tooltip title="Visualizza Calendario">
              <IconButton
                edge="end"
                color="inherit"
                aria-label="calendario"
                onClick={() => navigate('/CalendarPage')}
                className="calendar-icon-button"
              >
                <FaCalendarAlt />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Spazio per compensare l'AppBar fissa */}

        {/* Contenuto principale */}
        <Container maxWidth="lg" className="library-container-content">
          <Grid container spacing={4}>
            {documents.map((doc, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card className="library-card">
                  <CardMedia
                    component="img"
                    height="140"
                    image={doc.image}
                    alt={doc.title}
                    className="card-media"
                  />
                  <CardContent className="card-content">
                    <Typography gutterBottom variant="h5" component="div" className="card-title">
                      {doc.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary" className="card-description">
                      {doc.description}
                    </Typography>
                  </CardContent>
                  <CardActions className="card-actions">
                    <Button
                      variant="outlined"
                      color="primary"
                      href={doc.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="open-button"
                    >
                      Apri
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Footer */}
        <footer className="library-footer">
          <div className="social-buttons">
            <a
              href="https://www.tuosito.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Sito Internet"
            >
              <FaGlobe />
            </a>
            <a
              href="https://www.facebook.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://wa.me/tuonumber"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="WhatsApp"
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://www.instagram.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.pinterest.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Pinterest"
            >
              <FaPinterestP />
            </a>
          </div>
          <p className="footer-text">
            &copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore - Monserrato
          </p>
        </footer>
      </div>
    </div>
  );
}

export default Library;
