// src/pages/Login.js

import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import '../styles/Auth.css';
import { FaGlobe, FaFacebookF, FaWhatsapp, FaInstagram, FaPinterestP } from 'react-icons/fa';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Previene il refresh della pagina
    try {
      // Autentica l'utente
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Recupera lo stato e il ruolo dell'utente da Firestore
      const userDoc = await getDoc(doc(db, 'users', user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();

        if (userData.status === 'approved') {
          if (userData.role === 'admin') {
            navigate('/admin'); // Reindirizza alla dashboard admin
          } else {
            navigate('/dashboard'); // Reindirizza alla dashboard utente
          }
        } else if (userData.status === 'pending') {
          alert('Il tuo account è in attesa di approvazione.');
          await auth.signOut();
        } else if (userData.status === 'rejected') {
          alert('Il tuo account è stato rifiutato.');
          await auth.signOut();
        } else {
          alert('Stato account non riconosciuto.');
          await auth.signOut();
        }
      } else {
        alert('Documento utente non trovato.');
        await auth.signOut();
      }
    } catch (error) {
      console.error('Errore durante il login:', error);

      // Gestione specifica degli errori di autenticazione
      let errorMessage = 'Si è verificato un errore durante il login.';
      if (error.code === 'auth/invalid-email') {
        errorMessage = 'Email non valida.';
      } else if (error.code === 'auth/user-disabled') {
        errorMessage = 'L\'account utente è stato disabilitato.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'Utente non trovato.';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Password errata.';
      } else if (error.code === 'auth/too-many-requests') {
        errorMessage = 'Troppi tentativi di accesso. Riprova più tardi.';
      }

      alert(`Errore durante il login: ${errorMessage}`);
    }
  };

  return (
    <div className="auth-container">
      {/* Header con Logo */}
      <header className="auth-header">
        <img src="/images/tuoLogo.png" alt="Logo" className="auth-logo" />
      </header>
      
      {/* Main Content */}
      <main className="auth-main">
        <h2 className="auth-title">Accedi</h2>
        <form onSubmit={handleLogin} className="auth-form">
          <input
            className="input"
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            className="input"
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className="button" type="submit">
            Accedi
          </button>
        </form>
        <div className="auth-links">
          <Link to="/resetpassword" className="auth-link">Password dimenticata?</Link>
          <Link to="/signup" className="auth-link">Registrati</Link>
          <Link to="/" className="auth-link">Torna a Benvenuto</Link>
        </div>
      </main>
      
      {/* Footer */}
      <footer className="auth-footer">
        <div className="social-buttons">
          <a href="https://www.tuosito.com" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Sito Internet">
            <FaGlobe />
          </a>
          <a href="https://www.facebook.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Facebook">
            <FaFacebookF />
          </a>
          <a href="https://wa.me/tuonumber" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="WhatsApp">
            <FaWhatsapp />
          </a>
          <a href="https://www.instagram.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href="https://www.pinterest.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Pinterest">
            <FaPinterestP />
          </a>
        </div>
        <p className="footer-text">&copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore - Monserrato</p>
      </footer>
    </div>
  );
}

export default Login;
