// src/App.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Welcome from './pages/Welcome';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Dashboard from './pages/Dashboard';
import CalendarPage from './pages/CalendarPage';
import AdminDashboard from './pages/AdminDashboard'; // Se hai aggiunto AdminDashboard
import ProtectedRoute from './components/ProtectedRoute';
import ResetPassword from './pages/ResetPassword';
import Library from './pages/Library';
import LibraryDetail from './pages/LibraryDetails';
import './styles/Auth.css'; // Importa il CSS comune

function App() {
  return (
    <Routes>
      <Route path="/" element={<Welcome />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/admindashboard" element={<AdminDashboard />} />
      <Route path="/Library" element={<Library />} />
      <Route path="/Library/:folder" element={<LibraryDetail />} />
      <Route 
        path="/dashboard" 
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        } 
      />
      <Route 
        path="/admin" 
        element={
          <ProtectedRoute adminOnly={true}>
            <AdminDashboard />
          </ProtectedRoute>
        } 
      />
      <Route path="/calendarpage" element={<CalendarPage />} />
      {/* Aggiungi altre route qui */}
    </Routes>
  );
}

export default App;
