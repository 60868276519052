// src/config/SidebarLinks.js

import React from 'react';
import {
  FaTachometerAlt,
  FaBook,
  FaBookOpen,
  FaFileAlt,
  FaCalendar,
} from 'react-icons/fa';

const sidebarLinks = [
  // Link visibile solo all'amministratore
  {
    id: 'adminDashboard',
    title: 'Admin Dashboard',
    path: '/admindashboard',
    icon: <FaTachometerAlt />,
    adminOnly: true,
  },
  // Link comuni
  {
    id: 'home',
    title: 'Home',
    path: '/dashboard',
    icon: <FaTachometerAlt />,
  },
  {
    id: 'progettoCatechistico',
    title: 'Progetto Catechistico',
    path: 'https://drive.google.com/file/d/1UzSajhvHTs8NHm3uK9MBlGziYMwNnJ8o/view?usp=sharing',
    icon: <FaBookOpen />,
    external: true,
  },
  {
    id: 'calendario',
    title: 'Calendario',
    path: '/CalendarPage',
    icon: <FaCalendar />,
  },
  // Cartella: Libreria
  {
    id: 'libreria',
    title: 'Libreria',
    icon: <FaBook />,
    subLinks: [
      {
        id: 'catechismo',
        title: 'Catechismo',
        path: 'https://drive.google.com/file/d/1dj7pkiO5vhihwbxf_bndaRPGXgh8ty-5/view?usp=drive_link',
        external: true,
      },
      {
        id: 'compendioCatechismo',
        title: 'Compendio del Catechismo',
        path: 'https://drive.google.com/file/d/1HrIUX7iDSvq4OcCkouanu2B6oZWnZEJS/view?usp=drive_link',
        external: true,
      },
      {
        id: 'lasciateBambini',
        title: 'Lasciate che i bambini vengano a Me',
        path: 'https://drive.google.com/file/d/1hjNf-G4LZMt4E-7gY3kQVER_fVlVhfX0/view?usp=drive_link',
        external: true,
      },
      {
        id: 'ioSonoConVoi',
        title: 'Io sono con voi',
        path: 'https://drive.google.com/file/d/1llozcD_ww-fXuhzq-pQHj099qTpO289Y/view?usp=drive_link',
        external: true,
      },
      {
        id: 'veniteConMe',
        title: 'Venite con Me',
        path: 'https://drive.google.com/file/d/1EJi0agSOQIfU7OhnKyld4gPdEqWkoKch/view?usp=drive_link',
        external: true,
      },
      {
        id: 'sareteMieiTestimoni',
        title: 'Sarete miei testimoni',
        path: 'https://drive.google.com/file/d/1ClMyKwHD5EBICGmgcoJ-TrC5igjaLwxZ/view?usp=drive_link',
        external: true,
      },
      {
        id: 'viHoChiamatoAmici',
        title: 'Vi ho chiamato amici',
        path: 'https://drive.google.com/file/d/1Vr5DXlO1_YQAm-SZhcmqzB781sqD2PJa/view?usp=drive_link',
        external: true,
      },
      {
        id: 'iMinisteriIstituiti',
        title: 'I Ministeri istituiti',
        path: 'https://drive.google.com/file/d/1bsDezIsFIu8_EhFHU-K_9HwZCLyxOldm/view?usp=drive_link',
        external: true,
      },
      {
        id: 'notaMinisteroCatechisti',
        title: 'Nota Ministero dei Catechisti',
        path: 'https://drive.google.com/file/d/1ZPZFXAq5mZb6fllRFCI-K_dYwJvy6J01/view?usp=drive_link',
        external: true,
      },
    ],
  },
  // Cartella: Documenti
  {
    id: 'documenti',
    title: 'Documenti',
    icon: <FaFileAlt />,
    subLinks: [
      {
        id: 'caritasInVeritate',
        title: 'Caritas in Veritate',
        path: 'https://drive.google.com/file/d/18GYoWkLRnNsHBoy1wJU2cHGZC8YfkwiE/view?usp=drive_link',
        external: true,
      },
      {
        id: 'gaudiumEtSpes',
        title: 'Gaudium et Spes',
        path: 'https://drive.google.com/file/d/18GYoWkLRnNsHBoy1wJU2cHGZC8YfkwiE/view?usp=drive_link',
        external: true,
      },
      {
        id: 'evangeliiGaudium',
        title: 'Evangelii Gaudium',
        path: 'https://drive.google.com/file/d/186xQ30Pw2b4DK9Q3RsPq3KibtID-tomX/view?usp=drive_link',
        external: true,
      },
      {
        id: 'gravissimumEducationis',
        title: 'Gravissimum Educationis',
        path: 'https://drive.google.com/file/d/1uQw30QgIvHvInAxzF12hBuLy1XLdWdXu/view?usp=drive_link',
        external: true,
      },
      {
        id: 'educareVitaBuonaVangelo',
        title: 'Educare alla vita buona del Vangelo',
        path: 'https://drive.google.com/file/d/1yz2ELR6u37K2260ZY-bRco-KArI9NNlr/view?usp=drive_link',
        external: true,
      },
      {
        id: 'educareInfinitoPresente',
        title: 'Educare, infinito presente',
        path: 'https://drive.google.com/file/d/169ZIDBC1yv6_5l58oqoxE8710kMKDISm/view?usp=drive_link',
        external: true,
      },
      {
        id: 'rinnovamentoCatechesi',
        title: 'Il rinnovamento della Catechesi',
        path: 'https://drive.google.com/file/d/1K47ZUCuKJhrfLJ5uTXXoesXqe8dWsX_X/view?usp=drive_link',
        external: true,
      },
      {
        id: 'direttorioCatechesi',
        title: 'Direttorio per la Catechesi',
        path: 'https://drive.google.com/file/d/14rEQnYD1e2hJF2aQJiDll2y37k01_o98/view?usp=drive_link',
        external: true,
      },
      {
        id: 'setteViziPrimaParte',
        title: 'I sette Vizi - prima parte',
        path: 'https://drive.google.com/file/d/16J40XZFFOhMANEvL9ftA7vSOmHRoeht5/view?usp=drive_link',
        external: true,
      },
    ],
  },
  // Cartella: Testi di Papa Benedetto XVI
  {
    id: 'testiBenedetto',
    title: 'Testi di Papa Benedetto XVI',
    icon: <FaBookOpen />,
    subLinks: [
      {
        id: 'ultimeConversazioni',
        title: 'Ultime Conversazioni',
        path: 'https://drive.google.com/file/d/1s-VrJlIAlShVt5wcpnGUdFJECQjUtZSo/view?usp=drive_link',
        external: true,
      },
      {
        id: 'laChiesaViva',
        title: 'La Chiesa è viva',
        path: 'https://drive.google.com/file/d/1f8ZfQnMiFz6dahpI6GKVrflofJ4pkV-U/view?usp=drive_link',
        external: true,
      },
      {
        id: 'infanziaGesù',
        title: "L'infanzia di Gesù",
        path: 'https://drive.google.com/file/d/1kpdll4xmh06-F2VXQgDLaXNdNVcN5kop/view?usp=drive_link',
        external: true,
      },
      {
        id: 'camminoVerità',
        title: 'In Cammino alla ricerca della Verità',
        path: 'https://drive.google.com/file/d/1lC8z0PqAsJrI-WfWLe2vhJ5akFGVud21/view?usp=drive_link',
        external: true,
      },
      {
        id: 'dioFedeFilosofi',
        title: 'Il Dio della Fede ed il Dio dei filosofi',
        path: 'https://drive.google.com/file/d/1dQ7aEljb3bYWQ4qMOXOKB2MfBTNKfW9o/view?usp=drive_link',
        external: true,
      },
      {
        id: 'apostoliPrimiDiscepoli',
        title: 'Gli Apostoli ed i primi discepoli di Cristo',
        path: 'https://drive.google.com/file/d/1tVuBq5mxAdzK2q0q9wUGvWPapsC8N2lf/view?usp=drive_link',
        external: true,
      },
      {
        id: 'gesuNazareth',
        title: 'Gesù di Nazareth',
        path: 'https://drive.google.com/file/d/1O50lExcQLkN5q1AvPlJyAJoX8wNEKddG/view?usp=drive_link',
        external: true,
      },
      {
        id: 'gesuNazarethSeconda',
        title: 'Gesù di Nazareth - seconda parte',
        path: 'https://drive.google.com/file/d/10Kw1epJ68xNquXcWT6Uyh_vckyMNeLFZ/view?usp=drive_link',
        external: true,
      },
      {
        id: 'dioEsiste',
        title: 'Dio esiste',
        path: 'https://drive.google.com/file/d/1CCLhPziHbryJAXAbBuWpNUoQX8g4Nixm/view?usp=drive_link',
        external: true,
      },
      {
        id: 'padreNostro',
        title: 'Padre Nostro',
        path: 'https://drive.google.com/file/d/1UflJu2pDd1PzR9yLwDuZCOFFZq5VPH3E/view?usp=drive_link',
        external: true,
      },
    ],
  },
  // Cartella: Testi di Papa Giovanni Paolo II
  {
    id: 'testiGiovanni',
    title: 'Testi di Papa Giovanni Paolo II',
    icon: <FaBookOpen />,
    subLinks: [
      {
        id: 'varcareSoglia',
        title: 'Varcare la soglia della Speranza',
        path: 'https://drive.google.com/file/d/1hTGQNimyUUJ5xcK6WufKJWExy82LZAF8/view?usp=drive_link',
        external: true,
      },
      {
        id: 'uomoDonnaCreo',
        title: 'Uomo e Donna li Creò',
        path: 'https://drive.google.com/file/d/1O3u0PSi-Gt8Kk2ZAbRxx-KEglzLLiqgN/view?usp=drive_link',
        external: true,
      },
      {
        id: 'tutteEncicliche',
        title: 'Tutte le Encicliche',
        path: 'https://drive.google.com/file/d/1cYbpFU7Q7n2LX4yvGf52uWNYvzTUK_dB/view?usp=drive_link',
        external: true,
      },
      {
        id: 'spalancatePorteCristo',
        title: 'Spalancate le porte a Cristo!',
        path: 'https://drive.google.com/file/d/1JSfotxg0A12Vo_jBaiHV8vFj7MjUDgTB/view?usp=drive_link',
        external: true,
      },
      {
        id: 'mariaMammaTutti',
        title: 'Maria mamma di tutti',
        path: 'https://drive.google.com/file/d/1wJTNfEG5qDKaumKmI2yCoh-W0VDbJCEU/view?usp=drive_link',
        external: true,
      },
    ],
  },
  // Cartella: Testi di Papa Francesco
  {
    id: 'testiFrancesco',
    title: 'Testi di Papa Francesco',
    icon: <FaBookOpen />,
    subLinks: [
      {
        id: 'viChiedoNomeDio',
        title: 'Vi chiedo in nome di Dio',
        path: 'https://drive.google.com/file/d/1s5SpNAzdzb_QMrfhF5JY1zKzfVtFgG3u/view?usp=drive_link',
        external: true,
      },
      {
        id: 'riflessioneRadiciFede',
        title: 'Una riflessione sulle radici della nostra fede',
        path: 'https://drive.google.com/file/d/1CEBNCgcvEu65jeAf4hQEkp4fO6ctiXoj/view?usp=drive_link',
        external: true,
      },
      {
        id: 'tuttiInsiemeCeLaFaremo',
        title: 'Tutti insieme ce la faremo',
        path: 'https://drive.google.com/file/d/1W69aIXhVblNukOPrqEtHTILwS7Y5ynr2/view?usp=drive_link',
        external: true,
      },
      {
        id: 'tiVoglioFelice',
        title: 'Ti voglio felice',
        path: 'https://drive.google.com/file/d/10SYnto0fqoJzaQ-3b6BJM-Mrucg3jKV4/view?usp=drive_link',
        external: true,
      },
      {
        id: 'ritorniamoASognare',
        title: 'Ritorniamo a sognare',
        path: 'https://drive.google.com/file/d/1mLFezKi1XLwlfA-k6h_acSNKYl71VqZK/view?usp=drive_link',
        external: true,
      },
      {
        id: 'quandoPapaChiama',
        title: 'Quando il Papa chiama',
        path: 'https://drive.google.com/file/d/1ZLBcnvsz3qp76NuggRcnRgXx-3tvk_EK/view?usp=drive_link',
        external: true,
      },
      {
        id: 'inDialogoConMondo',
        title: 'In dialogo con il mondo',
        path: 'https://drive.google.com/file/d/1HmJeDMqM8aIzQYdHepH5jLI6MqHDktwl/view?usp=drive_link',
        external: true,
      },
      {
        id: 'ilMioPresepe',
        title: 'Il mio presepe',
        path: 'https://drive.google.com/file/d/1fpN9GlXmlgWOIRTcnJ-LxkoyBbm3gT3z/view?usp=drive_link',
        external: true,
      },
      {
        id: 'dioNellaCitta',
        title: 'Dio nella città',
        path: 'https://drive.google.com/file/d/1REbMU6Fpk1AeYWK0Lp_a2Rwn509Ojdkr/view?usp=drive_link',
        external: true,
      },
      {
        id: 'dioPoeta',
        title: 'Dio è un poeta',
        path: 'https://drive.google.com/file/d/18vydddM90QcmRrDHYKH2Mlc9kFtYeNOa/view?usp=drive_link',
        external: true,
      },
      {
        id: 'dioGiovane',
        title: 'Dio è giovane',
        path: 'https://drive.google.com/file/d/1UwLPwl_XtO4OmxO6O-1KMVfaqaZ2BvOy/view?usp=drive_link',
        external: true,
      },
      {
        id: 'controGuerra',
        title: 'Contro la guerra',
        path: 'https://drive.google.com/file/d/1nZVIRmsL2cPnOSuj8XzwT7TJsDy-pIev/view?usp=drive_link',
        external: true,
      },
      {
        id: 'chePaceSiaConTe',
        title: 'Che la pace sia con te',
        path: 'https://drive.google.com/file/d/130JVaoxlPY_OzKnJeVwtpEWP0HIr9SzA/view?usp=drive_link',
        external: true,
      },
      {
        id: 'cercaTuoOrizzonte',
        title: 'Cerca il tuo orizzonte',
        path: 'https://drive.google.com/file/d/1nJdzEJGikb4htlPmfw5gO0uSQGvzkQzy/view?usp=drive_link',
        external: true,
      },
      {
        id: 'cambiamo',
        title: 'Cambiamo!',
        path: 'https://drive.google.com/file/d/1magd77Cp18sujCDvqvxFX7496abe_ubi/view?usp=drive_link',
        external: true,
      },
      {
        id: 'buongiornoFrancesco',
        title: 'Buongiorno, sono Francesco',
        path: 'https://drive.google.com/file/d/1vTYmGfSQnvYrNf0_xZJm2lel58AUum7N/view?usp=drive_link',
        external: true,
      },
      {
        id: 'buonaVita',
        title: 'Buona vita',
        path: 'https://drive.google.com/file/d/1jH9gbyqk5tr4zwIoVfK3EIw6e75dUDVB/view?usp=drive_link',
        external: true,
      },
      {
        id: 'adessoDomande',
        title: 'Adesso fate le vostre domande',
        path: 'https://drive.google.com/file/d/148k1yit2D7otqcL2SvjrfZDTVHQvRZGD/view?usp=drive_link',
        external: true,
      },
      {
        id: 'umiltaStupore',
        title: "l'umiltà e lo stupore",
        path: 'https://drive.google.com/file/d/1OpOnHC7O6K7WFN6iogGu0gEXQUbGku51/view?usp=drive_link',
        external: true,
      },
      {
        id: 'carlMariaMartiniCredoVitaEterna',
        title: 'Carlo Maria Martini - Credo la vita eterna',
        path: 'https://drive.google.com/file/d/1iO0BH07wmADwuHLFhar0h06sGyMl0kPA/view?usp=drive_link',
        external: true,
      },
      {
        id: 'cPellegrinoLettereTimoteo',
        title: 'C. Pellegrino - Lettere a Timoteo. Introduzione, traduzione e commento',
        path: 'https://drive.google.com/file/d/18dCXEC5RnN4PP0wXRhDmdmkKCU4p0ytW/view?usp=drive_link',
        external: true,
      },
      {
        id: 'cDoglioApocalisse',
        title: 'C. Doglio - Apocalisse. Introduzione, traduzione e commento',
        path: 'https://drive.google.com/file/d/1WvhJ7kvQQ-yiQDEH4ea5esdysdGLwSmg/view?usp=drive_link',
        external: true,
      },
      {
        id: 'andreaTornielliPadrePioDemonio',
        title: 'Andrea Tornielli - Padre Pio e la lotta con il Demonio',
        path: 'https://drive.google.com/file/d/1V_iCaNvVdX6lDUF4cMDAlI0N2_4RGnAH/view?usp=drive_link',
        external: true,
      },
    ],
  },
  // Cartella: Testi di Autori Vari
  {
    id: 'testiVari',
    title: 'Testi di Autori Vari',
    icon: <FaBookOpen />,
    subLinks: [
      {
        id: 'sRomanelloLetteraGalati',
        title: 'S. Romanello - Lettera ai Galati. Introduzione, traduzione e commento',
        path: 'https://drive.google.com/file/d/1ypuNkxQp1VsKJol5zf33-WF-wAKMV-4z/view?usp=drive_link',
        external: true,
      },
      {
        id: 'renzoAllegriPadrePioSanto',
        title: 'Renzo Allegri - Padre Pio Un santo tra noi',
        path: 'https://drive.google.com/file/d/1YUcfcEqTkrh90XbXGOucUXkhUNEQJHWa/view?usp=drive_link',
        external: true,
      },
      {
        id: 'renzoAllegriTuPerTu',
        title: 'Renzo Allegri - A tu per tu con Padre Pio',
        path: 'https://drive.google.com/file/d/1bJvfaOyse-igYHuaepKKiIF1CJwKTjMF/view?usp=drive_link',
        external: true,
      },
      {
        id: 'georgGansweinVerita',
        title: "Georg Ganswein - Nient'altro che la Verità",
        path: 'https://drive.google.com/file/d/1677vsSm-pCCoHwWT1xRIk5pCpiYRyhDF/view?usp=drive_link',
        external: true,
      },
      {
        id: 'marioCugusiBachisioBandinu',
        title: "Mario Cugusi - Bachisio Bandinu - Sa Novena de Pascha 'e Nadale",
        path: 'https://drive.google.com/file/d/1LbZAWxyiU7TOQOsVbIpjTWlYex0Qw5jA/view?usp=drive_link',
        external: true,
      },
      {
        id: 'marcoTosattiPadrePioDemonio',
        title: 'Marco Tosatti - Padre Pio e il diavolo',
        path: 'https://drive.google.com/file/d/1cjOG76oGfxK80WJASGUd9uzeS-iDSVWP/view?usp=drive_link',
        external: true,
      },
      {
        id: 'luigiEpicocoCustodireFuoco',
        title: 'Luigi Maria Epicoco - Per custodire il fuoco. Vademecum dopo l\'apocalisse',
        path: 'https://drive.google.com/file/d/1xdE4LaRLlLszhSSXcwqfHKlgA3r1Owg9/view?usp=drive_link',
        external: true,
      },
      {
        id: 'luigiEpicocoPietraScartata',
        title: 'Luigi Maria Epicoco - La pietra scartata',
        path: 'https://drive.google.com/file/d/1K59J_voAnvyVAFKbhM9yfiixOQVm42oo/view?usp=drive_link',
        external: true,
      },
      {
        id: 'luigiEpicocoLuceFondo',
        title: 'Luigi Maria Epicoco - La luce in fondo. Attraversare i passaggi difficili della vita',
        path: 'https://drive.google.com/file/d/1B9L1dKY_0W_ZZzsdaaehrIipK2EAo-AL/view?usp=drive_link',
        external: true,
      },
      {
        id: 'gianfrancoRavasiAlfabetoDio',
        title: "Gianfranco Ravasi - L'alfabeto di Dio",
        path: 'https://drive.google.com/file/d/1OPD5AFXkaFHY-LOdeeLaZYd81j9LZDxt/view?usp=drive_link',
        external: true,
      },
      {
        id: 'gRosseAttiApostoli',
        title: "G. Rossé - Atti degli Apostoli. Introduzione, traduzione e commento",
        path: 'https://drive.google.com/file/d/1vivxkapf8yDuO_om_uGAyNNarpinQrOb/view?usp=drive_link',
        external: true,
      },
      {
        id: 'gPeregoMarco',
        title: "G. Perego - Marco. Introduzione, traduzione e commento",
        path: 'https://drive.google.com/file/d/13c2jwvs5BvNSG1f0Ws04nEn3M_Z6EBHR/view?usp=drive_link',
        external: true,
      },
      {
        id: 'fBianchiniSecondaLetteraCorinzi',
        title: "F. Bianchini - Seconda lettera ai Corinzi. Introduzione, traduzione e commento",
        path: 'https://drive.google.com/file/d/1fNiEF3L3rO-v4F9-xPhvIbj9bNhx8pB-/view?usp=drive_link',
        external: true,
      },
      {
        id: 'fBianchiniLetteraFilippesi',
        title: "F. Bianchini - Lettera ai Filippesi. Introduzione, traduzione e commento",
        path: 'https://drive.google.com/file/d/1iUVy8qtNKz1B-d8IzrCjtV03ql0auPXS/view?usp=drive_link',
        external: true,
      },
      {
        id: 'eugenioScalfariDioSocieta',
        title: "Eugenio Scalfari - Il Dio unico e la società moderna",
        path: 'https://drive.google.com/file/d/1jDa0p3YRRK_UNpBpDS1RgvMqR5PreJ2f/view?usp=drive_link',
        external: true,
      },
      {
        id: 'chiaraAmiranteDioGioco',
        title: 'Chiara Amirante - Dio è gioia. Papa Francesco incontra Nuovi Orizzonti',
        path: 'https://drive.google.com/file/d/1p-5gAo4XnBmeSHkepYVcqzbEUh6wcGH-/view?usp=drive_link',
        external: true,
      },
      {
        id: 'carloMariaMartiniCredoVitaEterna',
        title: 'Carlo Maria Martini - Credo la vita eterna',
        path: 'https://drive.google.com/file/d/1iO0BH07wmADwuHLFhar0h06sGyMl0kPA/view?usp=drive_link',
        external: true,
      },
      {
        id: 'cPellegrinoLettereTimoteo',
        title: 'C. Pellegrino - Lettere a Timoteo. Introduzione, traduzione e commento',
        path: 'https://drive.google.com/file/d/18dCXEC5RnN4PP0wXRhDmdmkKCU4p0ytW/view?usp=drive_link',
        external: true,
      },
      {
        id: 'cDoglioApocalisse',
        title: 'C. Doglio - Apocalisse. Introduzione, traduzione e commento',
        path: 'https://drive.google.com/file/d/1WvhJ7kvQQ-yiQDEH4ea5esdysdGLwSmg/view?usp=drive_link',
        external: true,
      },
      {
        id: 'andreaTornielliPadrePioDemonio',
        title: 'Andrea Tornielli - Padre Pio e la lotta con il Demonio',
        path: 'https://drive.google.com/file/d/1V_iCaNvVdX6lDUF4cMDAlI0N2_4RGnAH/view?usp=drive_link',
        external: true,
      },
    ],
  },
];

export default sidebarLinks;
