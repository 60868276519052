// src/components/StudentEditForm.js

import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  TextField,
  Button,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../styles/Form.css';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
  maxHeight: '90vh',
  overflowY: 'auto',
};

function StudentEditForm({ student, onClose, open }) {
  const [nome, setNome] = useState(student.nome || '');
  const [cognome, setCognome] = useState(student.cognome || '');
  const [dataNascita, setDataNascita] = useState(
    student.dataNascita ? new Date(student.dataNascita.seconds * 1000) : new Date()
  );
  const [indirizzo, setIndirizzo] = useState(student.indirizzo || '');
  const [telefonoPadre, setTelefonoPadre] = useState(student.telefonoPadre || '');
  const [telefonoMadre, setTelefonoMadre] = useState(student.telefonoMadre || '');
  const [note, setNote] = useState(student.note || '');
  const [present, setPresent] = useState(student.present || false);
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(student.photoURL || null);

  useEffect(() => {
    if (photo) {
      const objectUrl = URL.createObjectURL(photo);
      setPhotoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [photo]);

  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];
      setPhoto(selectedFile);
      setPhotoPreview(URL.createObjectURL(selectedFile));
    }
  };

  const handleUpdate = async () => {
    if (!nome || !cognome || !dataNascita || !indirizzo) {
      alert('Per favore, compila tutti i campi obbligatori.');
      return;
    }

    let photoURL = student.photoURL || '';
    if (photo) {
      const sanitizedFileName = photo.name.replace(/\s+/g, '_').replace(/[()]/g, '');
      const photoRef = ref(storage, `students/${Date.now()}_${sanitizedFileName}`);
      try {
        await uploadBytes(photoRef, photo);
        photoURL = await getDownloadURL(photoRef);
      } catch (error) {
        console.error('Errore durante l\'upload della foto:', error);
        alert('Si è verificato un errore durante l\'upload della foto.');
        return;
      }
    }

    const updatedStudent = {
      nome,
      cognome,
      dataNascita,
      indirizzo,
      telefonoPadre,
      telefonoMadre,
      note,
      present,
      photoURL,
    };

    try {
      const studentRef = doc(db, 'students', student.id);
      await updateDoc(studentRef, updatedStudent);
      onClose();
    } catch (error) {
      console.error('Errore durante l\'aggiornamento dello studente:', error);
      alert('Si è verificato un errore durante l\'aggiornamento dello studente.');
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="edit-student-modal">
      <Box sx={modalStyle}>
        <div className="form-container">
          <h2>Modifica Studente</h2>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Cognome"
                value={cognome}
                onChange={(e) => setCognome(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                selected={dataNascita}
                onChange={(date) => setDataNascita(date)}
                dateFormat="dd/MM/yyyy"
                customInput={
                  <TextField
                    label="Data di Nascita"
                    fullWidth
                    required
                  />
                }
                maxDate={new Date()}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Indirizzo di Residenza"
                value={indirizzo}
                onChange={(e) => setIndirizzo(e.target.value)}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Numero di Telefono del Padre"
                value={telefonoPadre}
                onChange={(e) => setTelefonoPadre(e.target.value)}
                fullWidth
                type="tel"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Numero di Telefono della Madre"
                value={telefonoMadre}
                onChange={(e) => setTelefonoMadre(e.target.value)}
                fullWidth
                type="tel"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                fullWidth
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="present-label">Presente</InputLabel>
                <Select
                  labelId="present-label"
                  value={present}
                  onChange={(e) => setPresent(e.target.value)}
                  label="Presente"
                >
                  <MenuItem value={true}>Sì</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className="photo-upload">
                <input
                  type="file"
                  accept="image/*"
                  onChange={handlePhotoChange}
                  style={{ display: 'none' }}
                  id="photo-edit-upload-input"
                />
                <label htmlFor="photo-edit-upload-input">
                  <Button variant="contained" component="span">
                    Carica Foto
                  </Button>
                </label>
                {photoPreview && (
                  <img
                    src={photoPreview}
                    alt="Anteprima Foto"
                    className="photo-preview"
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" color="primary" onClick={handleUpdate}>
                  Aggiorna
                </Button>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={onClose}>
                  Annulla
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Box>
    </Modal>
  );
}

export default StudentEditForm;
