// src/components/CatechistEditForm.js

import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import { updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  TextField,
  Button,
  Modal,
  Box,
} from '@mui/material';
import '../styles/Form.css';

function CatechistEditForm({ catechist, onClose, open }) {
  const [nome, setNome] = useState(catechist.nome || '');
  const [cognome, setCognome] = useState(catechist.cognome || '');
  const [indirizzo, setIndirizzo] = useState(catechist.indirizzo || '');
  const [telefono, setTelefono] = useState(catechist.telefono || '');
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(catechist.photoURL || null);

  useEffect(() => {
    if (photo) {
      const objectUrl = URL.createObjectURL(photo);
      setPhotoPreview(objectUrl);
      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [photo]);

  const handlePhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setPhoto(e.target.files[0]);
    }
  };

  const handleUpdate = async () => {
    let photoURL = catechist.photoURL || '';
    if (photo) {
      const photoRef = ref(storage, `catechists/${Date.now()}_${photo.name}`);
      await uploadBytes(photoRef, photo);
      photoURL = await getDownloadURL(photoRef);
    }

    const updatedCatechist = {
      nome,
      cognome,
      indirizzo,
      telefono,
      photoURL,
    };

    try {
      const catechistRef = doc(db, 'users', catechist.id);
      await updateDoc(catechistRef, updatedCatechist);
      onClose();
    } catch (error) {
      console.error('Errore durante l\'aggiornamento del catechista:', error);
      alert('Si è verificato un errore durante l\'aggiornamento del catechista.');
    }
  };

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modifica-catechista-modal">
      <Box className="form-modal">
        <div className="form-container">
          <h2>Modifica Catechista</h2>
          <form>
            <div className="form-group">
              <TextField
                label="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Cognome"
                value={cognome}
                onChange={(e) => setCognome(e.target.value)}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="form-group">
              <TextField
                label="Indirizzo di Residenza"
                value={indirizzo}
                onChange={(e) => setIndirizzo(e.target.value)}
                fullWidth
                margin="normal"
              />
            </div>
            <div className="form-group">
              <TextField
                label="Numero di Telefono"
                value={telefono}
                onChange={(e) => setTelefono(e.target.value)}
                fullWidth
                margin="normal"
                type="tel"
              />
            </div>
            <div className="form-group">
              <input
                type="file"
                accept="image/*"
                onChange={handlePhotoChange}
                className="file-input"
              />
              {photoPreview && (
                <img
                  src={photoPreview}
                  alt="Anteprima Foto"
                  className="photo-preview"
                />
              )}
            </div>
            <div className="form-actions">
              <Button variant="contained" color="primary" onClick={handleUpdate}>
                Aggiorna
              </Button>
              <Button variant="outlined" onClick={onClose}>
                Annulla
              </Button>
            </div>
          </form>
        </div>
      </Box>
    </Modal>
  );
}

export default CatechistEditForm;
