// src/pages/LibraryDetails.js

import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  Container,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Button, // Importato qui
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import {
  FaBook,
  FaCalendarAlt,
  FaBars,
  FaGlobe,
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaPinterestP,
} from 'react-icons/fa';
import '../styles/LibraryDetail.css';
import moment from 'moment';
import 'moment/locale/it';

import SidebarLinks from '../config/SidebarLinks';

function LibraryDetails() {
  const navigate = useNavigate();
  const { folder } = useParams(); // Es: 'Libreria'

  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Recupera le categorie e i link basati sulla cartella selezionata
  const categories = SidebarLinks.find(
    (link) => link.id.toLowerCase() === folder.toLowerCase()
  );

  const currentDateFormatted = moment().format('dddd, D MMMM YYYY');

  // Verifica se la cartella esiste
  if (!categories) {
    return (
      <div className="library-detail-container">
        <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
        <div className="library-detail-content">
          <AppBar position="fixed" color="primary" className="app-bar">
            <Toolbar>
              {/* Pulsante per aprire la Sidebar */}
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleSidebar}
                className="menu-button"
              >
                <FaBars />
              </IconButton>

              {/* Titolo della Libreria */}
              <Typography variant="h6" className="app-title">
                Libreria
              </Typography>

              {/* Spazio per allineare gli elementi */}
              <div style={{ flexGrow: 1 }} />

              {/* Contenitore per la Data */}
              <Typography variant="subtitle1" className="current-date">
                {currentDateFormatted}
              </Typography>

              {/* Icona del Libro */}
              <Tooltip title="Libreria">
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="libreria"
                  onClick={() => navigate('/Library')}
                  className="library-icon-button"
                >
                  <FaBook />
                </IconButton>
              </Tooltip>

              {/* Icona del Calendario */}
              <Tooltip title="Visualizza Calendario">
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="calendario"
                  onClick={() => navigate('/CalendarPage')}
                  className="calendar-icon-button"
                >
                  <FaCalendarAlt />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
          <Toolbar /> {/* Spazio per compensare l'AppBar fissa */}

          <Container maxWidth="lg" className="library-detail-container-content">
            <Typography variant="h4" align="center" gutterBottom>
              Cartella "{folder}" non trovata.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/Library')}
            >
              Torna alla Libreria
            </Button>
          </Container>

          {/* Footer */}
          <footer className="library-footer">
            <div className="social-buttons">
              <a
                href="https://www.tuosito.com"
                target="_blank"
                rel="noopener noreferrer"
                className="social-button"
                aria-label="Sito Internet"
              >
                <FaGlobe />
              </a>
              <a
                href="https://www.facebook.com/tuoprofilo"
                target="_blank"
                rel="noopener noreferrer"
                className="social-button"
                aria-label="Facebook"
              >
                <FaFacebookF />
              </a>
              <a
                href="https://wa.me/tuonumber"
                target="_blank"
                rel="noopener noreferrer"
                className="social-button"
                aria-label="WhatsApp"
              >
                <FaWhatsapp />
              </a>
              <a
                href="https://www.instagram.com/tuoprofilo"
                target="_blank"
                rel="noopener noreferrer"
                className="social-button"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
              <a
                href="https://www.pinterest.com/tuoprofilo"
                target="_blank"
                rel="noopener noreferrer"
                className="social-button"
                aria-label="Pinterest"
              >
                <FaPinterestP />
              </a>
            </div>
            <p className="footer-text">
              &copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore -
              Monserrato
            </p>
          </footer>
        </div>
      </div>
    );
  }

  return (
    <div className="library-detail-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="library-detail-content">
        {/* AppBar */}
        <AppBar position="fixed" color="primary" className="app-bar">
          <Toolbar>
            {/* Pulsante per aprire la Sidebar */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
              className="menu-button"
            >
              <FaBars />
            </IconButton>

            {/* Titolo della Libreria */}
            <Typography variant="h6" className="app-title">
              {categories.title}
            </Typography>

            {/* Spazio per allineare gli elementi */}
            <div style={{ flexGrow: 1 }} />

            {/* Contenitore per la Data */}
            <Typography variant="subtitle1" className="current-date">
              {currentDateFormatted}
            </Typography>

            {/* Icona del Libro */}
            <Tooltip title="Libreria">
              <IconButton
                edge="end"
                color="inherit"
                aria-label="libreria"
                onClick={() => navigate('/Library')}
                className="library-icon-button"
              >
                <FaBook />
              </IconButton>
            </Tooltip>

            {/* Icona del Calendario */}
            <Tooltip title="Visualizza Calendario">
              <IconButton
                edge="end"
                color="inherit"
                aria-label="calendario"
                onClick={() => navigate('/CalendarPage')}
                className="calendar-icon-button"
              >
                <FaCalendarAlt />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Spazio per compensare l'AppBar fissa */}

        {/* Contenuto principale */}
        <Container maxWidth="lg" className="library-detail-container-content">
          <Grid container spacing={4}>
            {categories.subLinks.map((category) => (
              <Grid item xs={12} sm={6} md={4} key={category.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div" gutterBottom>
                      {category.title}
                    </Typography>
                    <Grid container spacing={2}>
                      {category.subLinks.map((link) => (
                        <Grid item xs={12} key={link.id}>
                          <CardActionArea
                            onClick={() => window.open(link.path, '_blank')}
                            className="link-card-action"
                          >
                            <CardContent className="link-paper">
                              <Typography variant="subtitle1">
                                {link.title}
                              </Typography>
                              {/* Aggiungi ulteriori dettagli se necessario */}
                            </CardContent>
                          </CardActionArea>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>

        {/* Footer */}
        <footer className="library-footer">
          <div className="social-buttons">
            <a
              href="https://www.tuosito.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Sito Internet"
            >
              <FaGlobe />
            </a>
            <a
              href="https://www.facebook.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://wa.me/tuonumber"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="WhatsApp"
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://www.instagram.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.pinterest.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Pinterest"
            >
              <FaPinterestP />
            </a>
          </div>
          <p className="footer-text">
            &copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore -
            Monserrato
          </p>
        </footer>
      </div>
    </div>
  );
}

export default LibraryDetails;
