// src/components/StudentDetails.js

import React, { useState } from 'react';
import {
  Typography,
  Button,
  Modal,
  Box,
  Avatar,
  Grid,
} from '@mui/material';
import '../styles/Details.css';

function StudentDetails({ student, open, onClose }) {
  // **1. Chiamata a useState prima di qualsiasi condizione**
  const [openImage, setOpenImage] = useState(false);

  if (!student) return null;

  const formatDate = (date) => {
    if (!date) return 'N/A';
    if (date instanceof Date) {
      return date.toLocaleDateString('it-IT');
    } else if (date.toDate) {
      return date.toDate().toLocaleDateString('it-IT');
    } else if (typeof date === 'string') {
      return new Date(date).toLocaleDateString('it-IT');
    } else {
      return 'N/A';
    }
  };

  // **2. Funzioni per aprire e chiudere il modal dell'immagine**
  const handleOpenImage = () => setOpenImage(true);
  const handleCloseImage = () => setOpenImage(false);

  return (
    <>
      <Modal open={open} onClose={onClose} aria-labelledby="student-details-modal">
        <Box className="details-modal">
          <Box className="details-content">
            <Grid container spacing={2}>
              <Grid item xs={12} className="photo-container">
                {/* Avatar Clickabile per aprire il modal dell'immagine ingrandita */}
                <Box
                  onClick={handleOpenImage}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <Avatar
                    src={student.photoURL || '/icons/default-avatar.png'}
                    alt={`${student.nome} ${student.cognome}`}
                    className="details-avatar"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5" className="student-name">
                  {student.nome} {student.cognome}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className="student-detail">
                  <strong>Data di Nascita:</strong> {formatDate(student.dataNascita)}
                </Typography>
                <Typography variant="body1" className="student-detail">
                  <strong>Indirizzo:</strong> {student.indirizzo || 'N/A'}
                </Typography>
                <Typography variant="body1" className="student-detail">
                  <strong>Presente:</strong> {student.present ? 'Sì' : 'No'}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" className="student-detail">
                  <strong>Telefono Padre:</strong> {student.telefonoPadre || 'N/A'}
                </Typography>
                <Typography variant="body1" className="student-detail">
                  <strong>Telefono Madre:</strong> {student.telefonoMadre || 'N/A'}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body1" className="student-detail">
                  <strong>Note:</strong> {student.note || 'Nessuna nota'}
                </Typography>
              </Grid>
              <Grid item xs={12} className="button-container">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onClose}
                  className="close-button"
                >
                  Chiudi
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>

      {/* Modal per l'immagine ingrandita */}
      <Modal open={openImage} onClose={handleCloseImage} aria-labelledby="enlarged-image-modal">
        <Box className="image-modal">
          <img
            src={student.photoURL || '/icons/default-avatar.png'}
            alt={`${student.nome} ${student.cognome}`}
            className="enlarged-image"
          />
          {/* Pulsante di chiusura sotto l'immagine */}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCloseImage}
            className="close-image-button"
          >
            Chiudi
          </Button>
        </Box>
      </Modal>
    </>
  );
}

export default StudentDetails;
