// src/pages/Dashboard.js

import React, { useEffect, useState, useCallback } from 'react';
import { db, auth } from '../firebase';
import {
  collection,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
  setDoc,
  getDoc,
  addDoc,
  getDocs,
  query,
  where,
  Timestamp,
} from 'firebase/firestore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Avatar,
  TextField,
  Checkbox,
  IconButton,
  Badge,
  AppBar,
  Toolbar,
  Typography,
  Container,
  Paper,
  Grid,
  Tooltip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import StudentForm from '../components/StudentForm';
import StudentEditForm from '../components/StudentEditForm';
import StudentDetails from '../components/StudentDetails';
import Sidebar from '../components/Sidebar';
import { generatePdfReport, generateMonthlyPdfReport } from '../utils/generatePdfReport';
import {
  FaCalendarAlt,
  FaBook,
  FaBars,
  FaGlobe,
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaPinterestP,
} from 'react-icons/fa';
import moment from 'moment';
import '../styles/Dashboard.css';
import axios from 'axios';
import { RRule } from 'rrule';

function Dashboard() {
  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [editOpen, setEditOpen] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const [lessonPlan, setLessonPlan] = useState('');
  const [dashboardTitle, setDashboardTitle] = useState('Dashboard');
  const [catechists, setCatechists] = useState('');
  const [meetingDate, setMeetingDate] = useState('');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [upcomingEventsCount, setUpcomingEventsCount] = useState(0);
  const [birthdayNotifications, setBirthdayNotifications] = useState(0);
  const [user, setUser] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [savedPrograms, setSavedPrograms] = useState([]);
  const [selectedProgramId, setSelectedProgramId] = useState('');
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openGeneratePdfDialog, setOpenGeneratePdfDialog] = useState(false);
  const [openDeleteProgramDialog, setOpenDeleteProgramDialog] = useState(false);

  // Stati per la gestione del conflitto
  const [conflictProgram, setConflictProgram] = useState(null);
  const [openConflictDialog, setOpenConflictDialog] = useState(false);
  const [newMeetingDate, setNewMeetingDate] = useState('');
  const [openRenameDialog, setOpenRenameDialog] = useState(false);

  // Stati per la selezione del mese e dell'anno per il report mensile
  const [openMonthlyReportDialog, setOpenMonthlyReportDialog] = useState(false);
  const [selectedReportMonth, setSelectedReportMonth] = useState(moment().month() + 1); // 1-12
  const [selectedReportYear, setSelectedReportYear] = useState(moment().year());

  const navigate = useNavigate();

  // Imposta la localizzazione in italiano
  moment.locale('it');

  // Funzione per aprire/chiudere la Sidebar
  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  // Funzione per aggiungere un evento "Compleanno" quando si aggiunge uno studente
  const addBirthdayEvent = useCallback(
    async (student) => {
      if (student.dataNascita && user) {
        let birthdayDate;
        if (student.dataNascita instanceof Date) {
          birthdayDate = moment(student.dataNascita);
        } else if (student.dataNascita instanceof Timestamp) {
          birthdayDate = moment(student.dataNascita.toDate());
        } else if (typeof student.dataNascita === 'number') {
          birthdayDate = moment(new Date(student.dataNascita));
        } else {
          console.error(
            `Data di nascita non valida per lo studente: ${student.nome} ${student.cognome}`
          );
          return;
        }

        // Imposta la data di inizio alla prossima occorrenza del compleanno
        const now = moment();
        birthdayDate.year(now.year());
        if (birthdayDate.isBefore(now, 'day')) {
          birthdayDate.add(1, 'year');
        }

        try {
          const eventsCollection = collection(db, 'events');
          const q = query(
            eventsCollection,
            where('userId', '==', user.uid),
            where('title', '==', `Compleanno - ${student.nome} ${student.cognome}`)
          );
          const snapshot = await getDocs(q);
          if (snapshot.empty) {
            await addDoc(eventsCollection, {
              userId: user.uid,
              title: `Compleanno - ${student.nome} ${student.cognome}`,
              start: birthdayDate.toDate(),
              end: moment(birthdayDate).add(1, 'hour').toDate(),
              recurrence: 'FREQ=YEARLY',
              category: 'Compleanno',
            });
            console.log(
              `Evento Compleanno per ${student.nome} ${student.cognome} creato con successo.`
            );
          } else {
            console.log(`Evento Compleanno per ${student.nome} ${student.cognome} già esistente.`);
          }
        } catch (error) {
          console.error("Errore durante l'aggiunta dell'evento Compleanno:", error);
        }
      }
    },
    [user]
  );

  // Funzione per aggiornare l'evento "Compleanno" quando si modifica uno studente
  const updateBirthdayEvent = useCallback(
    async (student) => {
      if (student.dataNascita && user) {
        let birthdayDate;
        if (student.dataNascita instanceof Date) {
          birthdayDate = moment(student.dataNascita);
        } else if (student.dataNascita instanceof Timestamp) {
          birthdayDate = moment(student.dataNascita.toDate());
        } else if (typeof student.dataNascita === 'number') {
          birthdayDate = moment(new Date(student.dataNascita));
        } else {
          console.error(
            `Data di nascita non valida per lo studente: ${student.nome} ${student.cognome}`
          );
          return;
        }

        // Imposta la data di inizio alla prossima occorrenza del compleanno
        const now = moment();
        birthdayDate.year(now.year());
        if (birthdayDate.isBefore(now, 'day')) {
          birthdayDate.add(1, 'year');
        }

        try {
          const eventsCollection = collection(db, 'events');
          const q = query(
            eventsCollection,
            where('userId', '==', user.uid),
            where('title', '==', `Compleanno - ${student.nome} ${student.cognome}`)
          );
          const snapshot = await getDocs(q);
          if (!snapshot.empty) {
            const eventDoc = snapshot.docs[0];
            await updateDoc(eventDoc.ref, {
              start: birthdayDate.toDate(),
              end: moment(birthdayDate).add(1, 'hour').toDate(),
            });
            console.log(
              `Evento Compleanno per ${student.nome} ${student.cognome} aggiornato con successo.`
            );
          } else {
            await addBirthdayEvent(student);
          }
        } catch (error) {
          console.error("Errore durante l'aggiornamento dell'evento Compleanno:", error);
        }
      }
    },
    [user, addBirthdayEvent]
  );

  // Stato per gli eventi
  const [events, setEvents] = useState([]);

  // Funzione per caricare gli eventi
  const loadEvents = useCallback(
    (userId) => {
      const eventsCollection = collection(db, 'events');
      const q = query(eventsCollection, where('userId', '==', userId));

      const unsubscribeEvents = onSnapshot(q, (snapshot) => {
        const eventsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          start: doc.data().start.toDate(),
          end: doc.data().end.toDate(),
          recurrence: doc.data().recurrence,
          category: doc.data().category,
        }));
        setEvents(eventsData);
      });

      return unsubscribeEvents;
    },
    []
  );

  // Recupera l'ID dell'utente autenticato e carica gli eventi
  useEffect(() => {
    let unsubscribeEvents;
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (!user) {
        navigate('/login');
      } else {
        setUser(user);
        // Recupera i dati persistenti
        fetchPersistentData(user.uid);
        // Carica gli eventi per le notifiche
        unsubscribeEvents = loadEvents(user.uid);
      }
    });

    return () => {
      if (unsubscribeEvents) unsubscribeEvents();
      unsubscribeAuth();
    };
  }, [navigate, loadEvents]);

  // Recupero dei dati degli studenti appartenenti all'utente corrente
  useEffect(() => {
    if (!user) return;

    const studentsCollection = collection(db, 'students');
    const q = query(studentsCollection, where('userId', '==', user.uid));
    const unsubscribeStudents = onSnapshot(q, (snapshot) => {
      const studentsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Ordina gli studenti per Cognome in ordine alfabetico
      studentsList.sort((a, b) => a.cognome.localeCompare(b.cognome));
      setStudents(studentsList);
    });

    return () => {
      unsubscribeStudents();
    };
  }, [user]);

  // Calcolo delle notifiche
  useEffect(() => {
    if (!events.length) {
      setUpcomingEventsCount(0);
      setBirthdayNotifications(0);
      return;
    }

    let upcomingEvents = 0;
    let birthdayEvents = 0;

    const now = moment().startOf('day');
    const endDate = moment().add(1, 'year').endOf('day'); // Limita l'intervallo a 1 anno

    events.forEach((event) => {
      let eventOccurrences = [];

      if (event.recurrence && event.recurrence !== 'none') {
        try {
          const ruleOptions = {
            ...RRule.parseString(event.recurrence),
            dtstart: event.start,
            until: endDate.toDate(),
          };

          // Se l'evento è un compleanno, impostiamo bymonth e bymonthday
          if (event.category === 'Compleanno') {
            ruleOptions.freq = RRule.YEARLY;
            ruleOptions.bymonth = moment(event.start).month() + 1;
            ruleOptions.bymonthday = moment(event.start).date();
            ruleOptions.dtstart = now.toDate();
          }

          const rule = new RRule(ruleOptions);
          const occurrences = rule.between(now.toDate(), endDate.toDate(), true);
          eventOccurrences = occurrences;
        } catch (error) {
          console.error(
            `Errore nel parsing della ricorrenza per l'evento "${event.title}":`,
            error
          );
        }
      } else {
        if (
          moment(event.start).isSameOrAfter(now) &&
          moment(event.start).isSameOrBefore(endDate)
        ) {
          eventOccurrences.push(event.start);
        }
      }

      if (eventOccurrences.length > 0) {
        if (event.category === 'Compleanno') {
          birthdayEvents += eventOccurrences.length;
        } else {
          upcomingEvents += eventOccurrences.length;
        }
      }
    });

    setBirthdayNotifications(birthdayEvents);
    setUpcomingEventsCount(upcomingEvents);
  }, [events]);

  // Recupero dei programmi salvati
  useEffect(() => {
    if (!user) return;

    const programsCollection = collection(db, 'programs');
    const q = query(programsCollection, where('userId', '==', user.uid));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const programsList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Ordina i programmi per meetingDate in ordine decrescente
      programsList.sort((a, b) => {
        const dateA = a.meetingDate ? new Date(a.meetingDate) : new Date(0);
        const dateB = b.meetingDate ? new Date(b.meetingDate) : new Date(0);
        return dateB - dateA;
      });
      setSavedPrograms(programsList);
    });

    return () => {
      unsubscribe();
    };
  }, [user]);

  // Funzione per recuperare i dati persistenti dell'utente
  const fetchPersistentData = async (userId) => {
    try {
      const docRef = doc(db, 'users', userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.dashboardTitle) setDashboardTitle(data.dashboardTitle);
        if (data.catechists) setCatechists(data.catechists);
        if (data.lessonPlan) setLessonPlan(data.lessonPlan);
        if (data.meetingDate) setMeetingDate(data.meetingDate);
      }
    } catch (error) {
      console.error('Errore durante il recupero dei dati persistenti:', error);
    }
  };

  // Funzione per salvare i dati persistenti dell'utente
  const savePersistentData = async (userId, data) => {
    try {
      const docRef = doc(db, 'users', userId);
      await setDoc(docRef, data, { merge: true });
    } catch (error) {
      console.error('Errore durante il salvataggio dei dati persistenti:', error);
    }
  };

  // Funzione per eliminare uno studente
  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, 'students', id));
    } catch (error) {
      console.error("Errore durante l'eliminazione dello studente:", error);
      alert("Si è verificato un errore durante l'eliminazione dello studente.");
    }
  };

  // Funzione per aprire il form di modifica dello studente
  const handleEdit = (student) => {
    setSelectedStudent(student);
    setEditOpen(true);
  };

  // Funzione per aprire i dettagli dello studente
  const handleDetails = (student) => {
    setSelectedStudent(student);
    setDetailsOpen(true);
  };

  // Funzione per aprire il form di aggiunta dello studente
  const handleAdd = () => {
    setAddOpen(true);
  };

  // Funzione per alternare lo stato di presenza di uno studente
  const toggleAttendance = async (student) => {
    try {
      const studentRef = doc(db, 'students', student.id);
      await updateDoc(studentRef, {
        present: !student.present,
      });
    } catch (error) {
      console.error("Errore durante l'aggiornamento della presenza:", error);
      alert("Si è verificato un errore durante l'aggiornamento della presenza.");
    }
  };

  // Funzione per aprire il dialogo di generazione PDF giornaliero
  const handleGeneratePdf = async () => {
    setOpenGeneratePdfDialog(true);
  };

  // Funzione per confermare la generazione del PDF giornaliero
  const confirmGeneratePdf = async () => {
    setOpenGeneratePdfDialog(false);

    // Converti meetingDate da 'YYYY-MM-DD' a 'DD/MM/YYYY'
    const currentDate = meetingDate
      ? moment(meetingDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
      : '';

    if (!currentDate) {
      alert("Per favore, inserisci la data dell'incontro.");
      return;
    }

    try {
      const { pdfBlob, fileName } = await generatePdfReport(
        students,
        currentDate,
        lessonPlan,
        dashboardTitle,
        catechists
      );

      // Tentiamo di aprire il PDF in una nuova finestra/tab
      try {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      } catch (openError) {
        console.error("Errore durante l'apertura del PDF:", openError);
      }

      // Funzione per convertire blob in base64
      const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result.split(',')[1];
            resolve(base64data);
          };
          reader.onerror = () => {
            reject(new Error('Errore durante la conversione del PDF in base64'));
          };
          reader.readAsDataURL(blob);
        });
      };

      // Attendi la conversione del blob in base64
      const base64data = await convertBlobToBase64(pdfBlob);

      console.log('Invio il report alla Cloud Function...');

      // Invia il report alla Cloud Function
      await axios.post(
        'https://us-central1-catechismonline-f295f.cloudfunctions.net/uploadReport',
        {
          fileName,
          fileType: 'application/pdf',
          fileData: base64data,
        }
      );

      alert('Report PDF generato e caricato su Google Drive con successo!');
    } catch (error) {
      console.error('Errore durante la generazione del report PDF:', error);
      if (error.response) {
        console.error('Errore nella risposta:', error.response.status, error.response.data);
      } else if (error.request) {
        console.error('Nessuna risposta ricevuta:', error.request);
      } else {
        console.error('Errore nella richiesta:', error.message);
      }
      alert('Si è verificato un errore durante la generazione del report.');
    }
  };

  // Funzione per cancellare la generazione del PDF giornaliero
  const cancelGeneratePdf = () => {
    setOpenGeneratePdfDialog(false);
  };

  // Funzione per aprire il dialogo di generazione PDF mensile
  const handleGenerateMonthlyPdf = async () => {
    setOpenMonthlyReportDialog(true);
  };

  // Funzione per confermare la generazione del PDF mensile
  const confirmGenerateMonthlyPdf = async () => {
    setOpenMonthlyReportDialog(false);

    // Utilizza il mese e l'anno selezionati
    const reportMonth = selectedReportMonth - 1; // moment.js usa 0-indexed months
    const reportYear = selectedReportYear;

    try {
      // Filtra i programmi del mese e anno selezionati
      const monthlyPrograms = savedPrograms.filter((program) => {
        const programDate = moment(program.meetingDate);
        return programDate.month() === reportMonth && programDate.year() === reportYear;
      });

      if (monthlyPrograms.length === 0) {
        alert("Non ci sono programmi salvati per il mese e l'anno selezionati.");
        return;
      }

      // Raccogli le presenze per il mese selezionato
      const attendanceData = students.map((student) => {
        const attendanceRecords = monthlyPrograms.map((program) => {
          const presenza = program.presenze.find((p) => p.studentId === student.id);
          return presenza ? presenza.present : false;
        });
        const totalPresences = attendanceRecords.filter((present) => present).length;
        return {
          cognome: student.cognome,
          nome: student.nome,
          presenze: attendanceRecords,
          totalPresences,
          photoURL: student.photoURL,
        };
      });

      // Genera il titolo aggiuntivo
      const reportMonthYear = moment()
        .month(reportMonth)
        .year(reportYear)
        .format('MMMM YYYY');
      const additionalTitle = `Report del mese di ${reportMonthYear}`;

      // Genera il nome del file
      const fileName = `Report_Mensile_${moment()
        .month(reportMonth)
        .year(reportYear)
        .format('MMMM_YYYY')}.pdf`;

      // Genera il PDF
      const { pdfBlob } = await generateMonthlyPdfReport(
        dashboardTitle,
        catechists,
        additionalTitle,
        monthlyPrograms,
        attendanceData
      );

      // Tentiamo di aprire il PDF in una nuova finestra/tab
      try {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
      } catch (openError) {
        console.error("Errore durante l'apertura del PDF:", openError);
      }

      // Funzione per convertire blob in base64
      const convertBlobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result.split(',')[1];
            resolve(base64data);
          };
          reader.onerror = () => {
            reject(new Error('Errore durante la conversione del PDF in base64'));
          };
          reader.readAsDataURL(blob);
        });
      };

      // Attendi la conversione del blob in base64
      const base64data = await convertBlobToBase64(pdfBlob);

      console.log('Invio il report mensile alla Cloud Function...');

      // Invia il report alla Cloud Function
      await axios.post(
        'https://us-central1-catechismonline-f295f.cloudfunctions.net/uploadReport',
        {
          fileName,
          fileType: 'application/pdf',
          fileData: base64data,
        }
      );

      alert('Report mensile PDF generato e caricato su Google Drive con successo!');
    } catch (error) {
      console.error('Errore durante la generazione del report mensile PDF:', error);
      if (error.response) {
        console.error('Errore nella risposta:', error.response.status, error.response.data);
      } else if (error.request) {
        console.error('Nessuna risposta ricevuta:', error.request);
      } else {
        console.error('Errore nella richiesta:', error.message);
      }
      alert('Si è verificato un errore durante la generazione del report mensile.');
    }
  };

  // Funzione per cancellare la generazione del PDF mensile
  const cancelGenerateMonthlyReport = () => {
    setOpenMonthlyReportDialog(false);
  };

  // Funzione per aprire il dialogo di salvataggio del programma
  const handleSaveProgram = () => {
    setOpenSaveDialog(true);
  };

  // Funzione per confermare il salvataggio del programma
  const confirmSaveProgram = async () => {
    setOpenSaveDialog(false);

    if (!meetingDate) {
      alert("Per favore, inserisci la data dell'incontro prima di salvare.");
      return;
    }

    try {
      const programsCollection = collection(db, 'programs');

      // Verifica se esiste già un programma per la data selezionata
      const q = query(
        programsCollection,
        where('userId', '==', user.uid),
        where('meetingDate', '==', meetingDate)
      );
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        // Se esiste, prendi il primo programma trovato (supponendo che la data sia unica)
        const existingProgram = snapshot.docs[0];
        setConflictProgram(existingProgram);
        setOpenConflictDialog(true);
      } else {
        // Se non esiste, salva il programma come nuovo
        await saveNewProgram();
      }
    } catch (error) {
      console.error('Errore durante il salvataggio del programma:', error);
      alert('Si è verificato un errore durante il salvataggio del programma.');
    }
  };

  // Funzione per salvare un nuovo programma
  const saveNewProgram = async () => {
    try {
      const programsCollection = collection(db, 'programs');

      // Raccogli le presenze attuali degli studenti
      const presenze = students.map((student) => ({
        studentId: student.id,
        present: student.present || false,
      }));

      const programData = {
        userId: user.uid,
        dashboardTitle,
        catechists,
        lessonPlan,
        meetingDate,
        createdAt: Timestamp.now(),
        presenze, // Aggiungi le presenze al programma
      };

      await addDoc(programsCollection, programData);
      alert('Programma salvato con successo!');
    } catch (error) {
      console.error('Errore durante il salvataggio del programma:', error);
      alert('Si è verificato un errore durante il salvataggio del programma.');
    }
  };

  // Funzione per cancellare il dialogo di salvataggio del programma
  const cancelSaveProgram = () => {
    setOpenSaveDialog(false);
  };

  // Funzione per sovrascrivere un programma esistente
  const handleOverwriteProgram = async () => {
    if (!conflictProgram) return;

    try {
      const programRef = doc(db, 'programs', conflictProgram.id);

      // Raccogli le presenze attuali degli studenti
      const presenze = students.map((student) => ({
        studentId: student.id,
        present: student.present || false,
      }));

      const updatedProgramData = {
        dashboardTitle,
        catechists,
        lessonPlan,
        meetingDate, // Mantieni la stessa data
        presenze, // Aggiorna le presenze
        updatedAt: Timestamp.now(),
      };

      await updateDoc(programRef, updatedProgramData);
      alert('Programma sovrascritto con successo!');
      setConflictProgram(null);
      setOpenConflictDialog(false);
    } catch (error) {
      console.error('Errore durante la sovrascrittura del programma:', error);
      alert('Si è verificato un errore durante la sovrascrittura del programma.');
    }
  };

  // Funzione per aprire il dialogo di rinomina del programma
  const handleRenameProgram = () => {
    setOpenConflictDialog(false);
    setOpenRenameDialog(true);
  };

  // Funzione per confermare la rinomina del programma con una nuova data
  const confirmRenameProgram = async () => {
    if (!newMeetingDate) {
      alert("Per favore, seleziona una nuova data per il programma.");
      return;
    }

    try {
      // Verifica se esiste già un programma per la nuova data
      const programsCollection = collection(db, 'programs');
      const q = query(
        programsCollection,
        where('userId', '==', user.uid),
        where('meetingDate', '==', newMeetingDate)
      );
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        alert("Esiste già un programma per la nuova data selezionata. Scegli un'altra data.");
        return;
      }

      // Aggiorna il programma esistente con la nuova data
      const programRef = doc(db, 'programs', conflictProgram.id);

      // Raccogli le presenze attuali degli studenti
      const presenze = students.map((student) => ({
        studentId: student.id,
        present: student.present || false,
      }));

      const updatedProgramData = {
        dashboardTitle,
        catechists,
        lessonPlan,
        meetingDate: newMeetingDate, // Aggiorna la data
        presenze, // Aggiorna le presenze
        updatedAt: Timestamp.now(),
      };

      await updateDoc(programRef, updatedProgramData);
      alert('Programma rinominato con successo!');
      setConflictProgram(null);
      setOpenConflictDialog(false);
      setOpenRenameDialog(false);
      setNewMeetingDate('');
    } catch (error) {
      console.error('Errore durante la rinomina del programma:', error);
      alert('Si è verificato un errore durante la rinomina del programma.');
    }
  };

  // Funzione per cancellare il dialogo di rinomina del programma
  const cancelRenameProgram = () => {
    setOpenRenameDialog(false);
    setNewMeetingDate('');
  };

  // Funzione per cancellare il dialogo di salvataggio del programma
  const handleCancelSaveProgram = () => {
    setConflictProgram(null);
    setOpenConflictDialog(false);
  };

  // Funzione per eliminare un programma salvato
  const handleDeleteProgram = () => {
    if (!selectedProgramId) {
      alert('Per favore, seleziona un programma da eliminare.');
      return;
    }
    setOpenDeleteProgramDialog(true);
  };

  // Funzione per confermare l'eliminazione del programma
  const confirmDeleteProgram = async () => {
    setOpenDeleteProgramDialog(false);

    try {
      await deleteDoc(doc(db, 'programs', selectedProgramId));
      alert('Programma eliminato con successo!');
      setSelectedProgramId('');
    } catch (error) {
      console.error("Errore durante l'eliminazione del programma:", error);
      alert("Si è verificato un errore durante l'eliminazione del programma.");
    }
  };

  // Funzione per cancellare l'eliminazione del programma
  const cancelDeleteProgram = () => {
    setOpenDeleteProgramDialog(false);
  };

  // Funzione per caricare un programma salvato
  const handleLoadProgram = async (event) => {
    const programId = event.target.value;
    setSelectedProgramId(programId);

    if (!programId) return;

    try {
      const programDoc = await getDoc(doc(db, 'programs', programId));
      if (programDoc.exists()) {
        const data = programDoc.data();
        setDashboardTitle(data.dashboardTitle || 'Dashboard');
        setCatechists(data.catechists || '');
        setLessonPlan(data.lessonPlan || '');
        setMeetingDate(data.meetingDate || '');

        // Aggiorna le presenze degli studenti
        if (data.presenze && Array.isArray(data.presenze)) {
          data.presenze.forEach(async (presenza) => {
            try {
              const studentRef = doc(db, 'students', presenza.studentId);
              await updateDoc(studentRef, {
                present: presenza.present,
              });
            } catch (error) {
              console.error(
                `Errore durante l'aggiornamento della presenza dello studente ID: ${presenza.studentId}`,
                error
              );
            }
          });
        }

        alert('Programma caricato con successo!');
      } else {
        alert('Programma non trovato.');
      }
    } catch (error) {
      console.error('Errore durante il caricamento del programma:', error);
      alert('Si è verificato un errore durante il caricamento del programma.');
    }
  };

  // Funzioni per la modifica del titolo
  const handleTitleClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (e) => {
    setDashboardTitle(e.target.value);
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    if (user) {
      savePersistentData(user.uid, { dashboardTitle });
    }
  };

  // Funzione per la modifica dei catechisti
  const handleCatechistsChange = (e) => {
    setCatechists(e.target.value);
    if (user) {
      savePersistentData(user.uid, { catechists: e.target.value });
    }
  };

  // Funzione per la modifica del programma della lezione
  const handleLessonPlanChange = (e) => {
    setLessonPlan(e.target.value);
    if (user) {
      savePersistentData(user.uid, { lessonPlan: e.target.value });
    }
  };

  // Funzione per la modifica della data dell'incontro di catechismo
  const handleMeetingDateChange = (e) => {
    setMeetingDate(e.target.value);
    if (user) {
      savePersistentData(user.uid, { meetingDate: e.target.value });
    }
  };

  // Funzione per navigare alla pagina Calendar
  const navigateToCalendar = () => {
    navigate('/CalendarPage');
    toggleSidebar(); // Chiude la Sidebar dopo la navigazione
  };

  // Funzione per navigare alla pagina Library
  const navigateToLibrary = () => {
    navigate('/Library');
    toggleSidebar(); // Chiude la Sidebar dopo la navigazione
  };

  const currentDateFormatted = moment().format('dddd, D MMMM YYYY');

  return (
    <div className="dashboard-container">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className="dashboard-content">
        {/* AppBar */}
        <AppBar position="fixed" color="primary" className="app-bar">
          <Toolbar>
            {/* Pulsante per aprire la Sidebar */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleSidebar}
              className="menu-button"
            >
              <FaBars />
            </IconButton>

            {/* Titolo della Dashboard */}
            <Typography variant="h6" className="app-title">
              {isEditingTitle ? (
                <TextField
                  value={dashboardTitle}
                  onChange={handleTitleChange}
                  onBlur={handleTitleBlur}
                  autoFocus
                  InputProps={{
                    style: {
                      color: 'white',
                      backgroundColor: 'rgba(255,255,255,0.2)',
                      borderRadius: '4px',
                    },
                  }}
                  size="small"
                />
              ) : (
                <span onClick={handleTitleClick} className="dashboard-title">
                  {dashboardTitle}
                </span>
              )}
            </Typography>

            {/* Spazio per allineare gli elementi */}
            <div style={{ flexGrow: 1 }} />

            {/* Contenitore per gli elementi a destra con maggiore spazio */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              {/* Contenitore per la Data */}
              <Typography variant="subtitle1" className="current-date">
                {currentDateFormatted}
              </Typography>

              {/* Menu a Tendina per i Programmi Salvati */}
              <FormControl variant="outlined" size="small" style={{ minWidth: 200 }}>
                <InputLabel id="select-program-label">Programmi Salvati</InputLabel>
                <Select
                  labelId="select-program-label"
                  id="select-program"
                  value={selectedProgramId}
                  onChange={handleLoadProgram}
                  label="Programmi Salvati"
                >
                  <MenuItem value="">
                    <em>Seleziona un Programma</em>
                  </MenuItem>
                  {savedPrograms.map((program) => (
                    <MenuItem key={program.id} value={program.id}>
                      {`Programma del ${moment(program.meetingDate).format('DD/MM/YYYY')}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* Pulsante per Salvare il Programma */}
              <Button variant="contained" color="secondary" onClick={handleSaveProgram}>
                Salva Programma
              </Button>

              {/* Pulsante per Eliminare il Programma Salvato */}
              <Button
                variant="contained"
                color="error"
                onClick={handleDeleteProgram}
                disabled={!selectedProgramId}
              >
                Elimina Programma
              </Button>

              {/* Icona del Calendario con Badge */}
              <Tooltip title="Visualizza Calendario">
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="calendario"
                  onClick={navigateToCalendar}
                  className="calendar-icon-button"
                >
                  <Badge
                    badgeContent={upcomingEventsCount}
                    color="error"
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: '#f44336',
                        color: 'white',
                        fontSize: '0.7rem',
                        height: '16px',
                        minWidth: '16px',
                        borderRadius: '8px',
                        transform: 'scale(1) translate(50%, -50%)',
                      },
                    }}
                  >
                    <Badge
                      badgeContent={birthdayNotifications}
                      color="warning"
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: '#ffeb3b',
                          color: '#000',
                          fontSize: '0.7rem',
                          height: '16px',
                          minWidth: '16px',
                          borderRadius: '8px',
                          transform: 'scale(1) translate(50%, 50%)',
                        },
                      }}
                    >
                      <FaCalendarAlt />
                    </Badge>
                  </Badge>
                </IconButton>
              </Tooltip>

              {/* Icona del Libro */}
              <Tooltip title="Libreria">
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="libreria"
                  onClick={navigateToLibrary}
                  className="library-icon-button"
                >
                  <FaBook />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Spazio per compensare l'AppBar fissa */}

        {/* Contenuto principale */}
        <Container maxWidth="lg" className="dashboard-container-content">
          <Grid container spacing={2}>
            {/* Campo per i catechisti */}
            <Grid item xs={12} md={6}>
              <Paper className="paper">
                <Typography variant="h6" className="section-title">
                  Nomi dei Catechisti
                </Typography>
                <p></p>
                <TextField
                  label="Inserisci i nomi dei catechisti"
                  value={catechists}
                  onChange={handleCatechistsChange}
                  fullWidth
                  variant="outlined"
                  className="input-box"
                  size="small"
                />
              </Paper>
            </Grid>
            {/* Campo per la data dell'incontro di catechismo */}
            <Grid item xs={12} md={6}>
              <Paper className="paper">
                <Typography variant="h6" className="section-title">
                  Data dell'Incontro di Catechismo
                </Typography>
                <p></p>
                <TextField
                  label="Inserisci la data dell'incontro"
                  type="date"
                  variant="outlined"
                  value={meetingDate}
                  onChange={handleMeetingDateChange}
                  fullWidth
                  className="input-box"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              </Paper>
            </Grid>
            {/* Programma della lezione */}
            <Grid item xs={12}>
              <Paper className="paper">
                <Typography variant="h6" className="section-title">
                  Programma dell'incontro del Giorno
                </Typography>
                <p></p>
                <TextField
                  label="Scrivi qui il programma della lezione"
                  multiline
                  rows={4}
                  variant="outlined"
                  value={lessonPlan}
                  onChange={handleLessonPlanChange}
                  fullWidth
                  className="input-box"
                  size="small"
                />
              </Paper>
            </Grid>
            {/* Azioni */}
            <Grid item xs={12} className="actions-container">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                className="add-button"
              >
                Aggiungi Studente
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGeneratePdf}
                className="generate-pdf-button"
                style={{ marginLeft: 10 }}
              >
                Genera e Carica Report PDF
              </Button>
              {/* Pulsante per Generare il Report Mensile */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateMonthlyPdf}
                className="generate-monthly-pdf-button"
                style={{ marginLeft: 10 }}
              >
                Genera e Carica Report Mensile PDF
              </Button>
            </Grid>
            {/* Tabella degli studenti */}
            <Grid item xs={12}>
              <Paper className="paper">
                <Table>
                  <TableHead className="table-header">
                    <TableRow>
                      <TableCell className="header-cell">Foto</TableCell>
                      <TableCell className="header-cell">Cognome</TableCell>
                      <TableCell className="header-cell">Nome</TableCell>
                      <TableCell className="header-cell">Data di Nascita</TableCell>
                      <TableCell className="header-cell">Presente</TableCell>
                      <TableCell className="header-cell">Azioni</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map((student) => (
                      <TableRow key={student.id}>
                        <TableCell>
                          {student.photoURL ? (
                            <Avatar src={student.photoURL} alt={student.nome} />
                          ) : (
                            <Avatar>{student.nome.charAt(0)}</Avatar>
                          )}
                        </TableCell>
                        <TableCell>{student.cognome}</TableCell>
                        <TableCell>{student.nome}</TableCell>
                        <TableCell>
                          {student.dataNascita
                            ? moment(
                                student.dataNascita instanceof Timestamp
                                  ? student.dataNascita.toDate()
                                  : student.dataNascita
                              ).format('DD/MM/YYYY')
                            : ''}
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={student.present || false}
                            onChange={() => toggleAttendance(student)}
                            color="primary"
                          />
                        </TableCell>
                        <TableCell className="actions-cell">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => handleDetails(student)}
                            size="small"
                            className="action-button"
                          >
                            Dettagli
                          </Button>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => handleEdit(student)}
                            size="small"
                            className="action-button"
                          >
                            Modifica
                          </Button>
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => handleDelete(student.id)}
                            size="small"
                            className="action-button"
                          >
                            Elimina
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Container>
        {/* Finestre Modali per aggiungere, modificare e visualizzare gli studenti */}
        {addOpen && (
          <StudentForm
            onClose={() => setAddOpen(false)}
            open={addOpen}
            onAddStudent={addBirthdayEvent} // Passa la funzione per aggiungere l'evento Compleanno
          />
        )}
        {editOpen && selectedStudent && (
          <StudentEditForm
            student={selectedStudent}
            onClose={() => setEditOpen(false)}
            open={editOpen}
            onUpdateStudent={updateBirthdayEvent} // Passa la funzione per aggiornare l'evento Compleanno
          />
        )}
        {detailsOpen && selectedStudent && (
          <StudentDetails
            student={selectedStudent}
            onClose={() => setDetailsOpen(false)}
            open={detailsOpen}
          />
        )}
        {/* Finestra di Conferma per la Generazione e Invio del PDF Giornaliero */}
        <Dialog
          open={openGeneratePdfDialog}
          onClose={cancelGeneratePdf}
          aria-labelledby="generate-pdf-dialog-title"
          aria-describedby="generate-pdf-dialog-description"
        >
          <DialogTitle id="generate-pdf-dialog-title">
            Conferma Generazione Report PDF
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="generate-pdf-dialog-description">
              Stai per generare ed inviare il report PDF. Vuoi procedere?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelGeneratePdf} color="primary">
              Annulla
            </Button>
            <Button onClick={confirmGeneratePdf} color="primary" autoFocus>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
        {/* Finestra di Conferma per il Salvataggio del Programma */}
        <Dialog
          open={openSaveDialog}
          onClose={cancelSaveProgram}
          aria-labelledby="save-program-dialog-title"
          aria-describedby="save-program-dialog-description"
        >
          <DialogTitle id="save-program-dialog-title">Conferma Salvataggio Programma</DialogTitle>
          <DialogContent>
            <DialogContentText id="save-program-dialog-description">
              Vuoi salvare il programma del giorno{' '}
              {meetingDate ? moment(meetingDate).format('DD/MM/YYYY') : ''}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelSaveProgram} color="primary">
              Annulla
            </Button>
            <Button onClick={confirmSaveProgram} color="primary" autoFocus>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
        {/* Finestra di Conferma per l'Eliminazione del Programma */}
        <Dialog
          open={openDeleteProgramDialog}
          onClose={cancelDeleteProgram}
          aria-labelledby="delete-program-dialog-title"
          aria-describedby="delete-program-dialog-description"
        >
          <DialogTitle id="delete-program-dialog-title">Conferma Eliminazione Programma</DialogTitle>
          <DialogContent>
            <DialogContentText id="delete-program-dialog-description">
              Sei sicuro di voler eliminare il programma del giorno{' '}
              {selectedProgramId
                ? moment(savedPrograms.find((p) => p.id === selectedProgramId)?.meetingDate).format(
                    'DD/MM/YYYY'
                  )
                : ''}
              ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelDeleteProgram} color="primary">
              Annulla
            </Button>
            <Button onClick={confirmDeleteProgram} color="error" autoFocus>
              Elimina
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog per gestire i conflitti durante il salvataggio */}
        <Dialog
          open={openConflictDialog}
          onClose={handleCancelSaveProgram}
          aria-labelledby="conflict-dialog-title"
          aria-describedby="conflict-dialog-description"
        >
          <DialogTitle id="conflict-dialog-title">Conflitto Programma</DialogTitle>
          <DialogContent>
            <DialogContentText id="conflict-dialog-description">
              Esiste già un programma per la data{' '}
              {meetingDate ? moment(meetingDate).format('DD/MM/YYYY') : ''}. Vuoi sovrascriverlo,
              rinominarlo o annullare l'operazione?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelSaveProgram} color="primary">
              Annulla
            </Button>
            <Button onClick={handleOverwriteProgram} color="secondary">
              Sovrascrivi
            </Button>
            <Button onClick={handleRenameProgram} color="primary">
              Rinomina
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog per Rinomina del Programma */}
        <Dialog
          open={openRenameDialog}
          onClose={cancelRenameProgram}
          aria-labelledby="rename-dialog-title"
          aria-describedby="rename-dialog-description"
        >
          <DialogTitle id="rename-dialog-title">Rinomina Programma</DialogTitle>
          <DialogContent>
            <DialogContentText id="rename-dialog-description">
              Inserisci una nuova data per il programma:
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="new-meeting-date"
              label="Nuova Data dell'Incontro"
              type="date"
              fullWidth
              variant="outlined"
              value={newMeetingDate}
              onChange={(e) => setNewMeetingDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelRenameProgram} color="primary">
              Annulla
            </Button>
            <Button onClick={confirmRenameProgram} color="primary" autoFocus>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
        {/* Dialog per la selezione del mese e anno del report mensile */}
        <Dialog
          open={openMonthlyReportDialog}
          onClose={cancelGenerateMonthlyReport}
          aria-labelledby="monthly-report-dialog-title"
          aria-describedby="monthly-report-dialog-description"
        >
          <DialogTitle id="monthly-report-dialog-title">
            Seleziona Mese e Anno per il Report Mensile
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="monthly-report-dialog-description">
              Per favore, seleziona il mese e l'anno per il quale desideri generare il report
              mensile.
            </DialogContentText>
            <Grid container spacing={2} style={{ marginTop: 10 }}>
              <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="select-month-label">Mese</InputLabel>
                  <Select
                    labelId="select-month-label"
                    id="select-month"
                    value={selectedReportMonth}
                    onChange={(e) => setSelectedReportMonth(e.target.value)}
                    label="Mese"
                  >
                    {moment.months().map((month, index) => (
                      <MenuItem key={index} value={index + 1}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Anno"
                  type="number"
                  variant="outlined"
                  value={selectedReportYear}
                  onChange={(e) => setSelectedReportYear(e.target.value)}
                  fullWidth
                  size="small"
                  inputProps={{ min: 2000, max: 2100 }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={cancelGenerateMonthlyReport} color="primary">
              Annulla
            </Button>
            <Button onClick={confirmGenerateMonthlyPdf} color="primary" autoFocus>
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
        {/* Footer */}
        <footer className="dashboard-footer">
          <div className="social-buttons">
            <a
              href="https://www.tuosito.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Sito Internet"
            >
              <FaGlobe />
            </a>
            <a
              href="https://www.facebook.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://wa.me/tuonumber"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="WhatsApp"
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://www.instagram.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.pinterest.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Pinterest"
            >
              <FaPinterestP />
            </a>
          </div>
          <p className="footer-text">
            &copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore - Monserrato
          </p>
        </footer>
      </div>
    </div>
  );
}

export default Dashboard;
