// src/pages/Signup.js

import React, { useState } from "react";
import { auth } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { useNavigate, Link } from "react-router-dom";
import "../styles/Auth.css";
import { FaGlobe, FaFacebookF, FaWhatsapp, FaInstagram, FaPinterestP } from 'react-icons/fa';

function Signup() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const navigate = useNavigate();

    const handleSignup = async (e) => {
        e.preventDefault(); // Previene il refresh della pagina
        if (password !== confirmPassword) {
            alert("Le password non coincidono.");
            return;
        }
        try {
            // Crea l'utente in Firebase Auth
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // La creazione del documento Firestore sarà gestita dalla Cloud Function 'onUserCreate'

            alert("Registrazione completata! Attendi l'approvazione da parte dell'admin.");
            navigate("/login");
        } catch (error) {
            console.error("Errore durante la registrazione:", error);
            if (error.code === "auth/email-already-in-use") {
                alert("L'email fornita è già in uso. Se hai dimenticato la password, puoi recuperarla.");
            } else if (error.code === "auth/invalid-email") {
                alert("L'email fornita non è valida.");
            } else if (error.code === "auth/weak-password") {
                alert("La password fornita è troppo debole. Scegli una password più complessa.");
            } else {
                alert("Errore durante la registrazione. Per favore, riprova.");
            }
        }
    };

    return (
        <div className="auth-container">
            {/* Header con Logo */}
            <header className="auth-header">
                <img src="/images/tuoLogo.png" alt="Logo" className="auth-logo" />
            </header>
            
            {/* Main Content */}
            <main className="auth-main">
                <h2 className="auth-title">Registrati</h2>
                <form onSubmit={handleSignup} className="auth-form">
                    <input
                        className="input"
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <input
                        className="input"
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <input
                        className="input"
                        type="password"
                        placeholder="Conferma Password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button className="button" type="submit">
                        Registrati
                    </button>
                </form>
                <div className="auth-links">
                    <Link to="/login" className="auth-link">Hai già un account? Accedi</Link>
                    <Link to="/resetpassword" className="auth-link">Recupera Password</Link>
                    <Link to="/" className="auth-link">Torna a Benvenuto</Link>
                </div>
            </main>
            
            {/* Footer */}
            <footer className="auth-footer">
                <div className="social-buttons">
                    <a href="https://www.tuosito.com" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Sito Internet">
                        <FaGlobe />
                    </a>
                    <a href="https://www.facebook.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Facebook">
                        <FaFacebookF />
                    </a>
                    <a href="https://wa.me/tuonumber" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="WhatsApp">
                        <FaWhatsapp />
                    </a>
                    <a href="https://www.instagram.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Instagram">
                        <FaInstagram />
                    </a>
                    <a href="https://www.pinterest.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-button" aria-label="Pinterest">
                        <FaPinterestP />
                    </a>
                </div>
                <p className="footer-text">&copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore - Monserrato</p>
      </footer>
    </div>
  );
}

export default Signup;
