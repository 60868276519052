// src/components/Sidebar.js

import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import '../styles/Sidebar.css';
import { FaSignOutAlt } from 'react-icons/fa';
import SidebarLinks from '../config/SidebarLinks'; // Importazione con il nome corretto
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

function Sidebar({ isOpen, toggleSidebar }) {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [openSubMenus, setOpenSubMenus] = useState({});

  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    return () => {
      unsubscribeAuth();
    };
  }, []);

  // Email dell'amministratore in minuscolo
  const adminEmail = 'diariodibordo@redentoremonserrato.it'.toLowerCase();

  const handleLogout = () => {
    auth.signOut();
    navigate('/login');
    toggleSidebar(); // Chiude la Sidebar dopo il logout
  };

  const handleClick = (id) => {
    setOpenSubMenus((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const renderLinks = (links) => {
    return links.map((link) => {
      // Verifica se il link è riservato all'amministratore
      if (link.adminOnly && (!user || user.email.toLowerCase() !== adminEmail)) {
        return null;
      }

      // Se il link ha subLinks, è una cartella
      if (link.subLinks) {
        return (
          <div key={link.id}>
            <ListItem button onClick={() => handleClick(link.id)}>
              <ListItemIcon className="sidebar-icon">{link.icon}</ListItemIcon>
              <ListItemText primary={link.title} />
              {openSubMenus[link.id] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openSubMenus[link.id]} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {link.subLinks.map((subLink) => (
                  <ListItem
                    button
                    key={subLink.id}
                    component={subLink.external ? 'a' : Link}
                    to={subLink.external ? undefined : subLink.path}
                    href={subLink.external ? subLink.path : undefined}
                    target={subLink.external ? '_blank' : undefined}
                    rel={subLink.external ? 'noopener noreferrer' : undefined}
                    onClick={toggleSidebar}
                    className="nested-link"
                  >
                    <ListItemText primary={subLink.title} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </div>
        );
      }

      // Link normale
      return (
        <ListItem
          button
          key={link.id}
          component={link.external ? 'a' : Link}
          to={link.external ? undefined : link.path}
          href={link.external ? link.path : undefined}
          target={link.external ? '_blank' : undefined}
          rel={link.external ? 'noopener noreferrer' : undefined}
          onClick={toggleSidebar}
        >
          <ListItemIcon className="sidebar-icon">{link.icon}</ListItemIcon>
          <ListItemText primary={link.title} />
        </ListItem>
      );
    });
  };

  return (
    <Drawer
      variant="temporary"
      open={isOpen}
      onClose={toggleSidebar}
      className="custom-drawer"
      ModalProps={{
        keepMounted: true, // Migliora le prestazioni su dispositivi mobili
      }}
    >
      <div className="sidebar-content">
        {/* Logo nella Sidebar */}
        <div className="sidebar-logo">
          <img src="/images/tuoLogo.png" alt="Logo" className="sidebar-logo-image" />
        </div>

        <List>
          {renderLinks(SidebarLinks)}
          {/* Pulsante Logout */}
          <ListItem button onClick={handleLogout} className="logout-button">
            <ListItemIcon className="sidebar-icon">
              <FaSignOutAlt />
            </ListItemIcon>
            <ListItemText primary="Esci" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}

export default Sidebar;
