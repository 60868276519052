// src/pages/Welcome.js

import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Welcome.css';
import { 
  FaGlobe, 
  FaFacebookF, 
  FaWhatsapp, 
  FaInstagram, 
  FaPinterestP, 
  FaCalendarAlt 
} from 'react-icons/fa';

// Lista estesa di vangeli (puoi aggiungerne altri secondo le tue esigenze)
const gospels = [
  { passage: 'Matteo 5:1-12 - Le Beatitudini' },
  { passage: 'Giovanni 14:6 - Gesù è la via, la verità e la vita' },
  { passage: 'Marco 10:45 - Il Figlio dell\'Uomo non è venuto per essere servito' },
  { passage: 'Luca 15:11-32 - La parabola del figlio prodigo' },
  { passage: 'Matteo 28:19-20 - Il Grande Mandato' },
  { passage: 'Giovanni 3:16 - L\'amore di Dio per il mondo' },
  { passage: 'Giovanni 11:25-26 - Gesù è la resurrezione e la vita' },
  { passage: 'Matteo 6:9-13 - Il Padre Nostro' },
  { passage: 'Luca 10:25-37 - La parabola del buon samaritano' },
  { passage: 'Giovanni 8:12 - La luce del mondo' },
  { passage: 'Matteo 7:7-12 - Chiedi, cercate, bussate' },
  { passage: 'Luca 6:27-36 - Ama i tuoi nemici' },
  { passage: 'Giovanni 15:5 - Io sono la vite' },
  { passage: 'Matteo 19:14 - Lasciate che i bambini vengano a me' },
  { passage: 'Marco 12:30-31 - Ama il Signore tuo Dio e il prossimo tuo' },
  { passage: 'Luca 4:18-19 - Il vangelo di Gesù' },
  { passage: 'Giovanni 10:10 - Io sono venuto perché abbiano la vita' },
  { passage: 'Matteo 22:37-40 - Il comandamento più grande' },
  { passage: 'Luca 18:27 - Per gli uomini è impossibile' },
  { passage: 'Giovanni 1:12 - A tutti quelli che lo hanno ricevuto' },
  { passage: 'Matteo 6:33 - Cercate prima il regno di Dio' },
  { passage: 'Luca 12:15 - Attenti a non mettere l\'anima nella ricchezza' },
  { passage: 'Giovanni 13:34-35 - Un nuovo comandamento' },
  { passage: 'Matteo 11:28-30 - Venite a me tutti voi' },
  { passage: 'Luca 24:46-47 - La crocifissione e la resurrezione di Gesù' },
  { passage: 'Giovanni 16:33 - Nel mondo avrete tribolazioni' },
  { passage: 'Matteo 25:40 - Tutto quello che avete fatto a uno solo di questi miei fratelli' },
  { passage: 'Luca 9:23 - Prendi la tua croce' },
  { passage: 'Giovanni 17:3 - La vita eterna' },
  { passage: 'Matteo 4:19 - Seguitemi' },
  { passage: 'Luca 6:38 - Date e vi sarà dato' },
];

function Welcome() {
  const [gospel, setGospel] = useState({});
  const [formattedDate, setFormattedDate] = useState('');

  useEffect(() => {
    // Ottieni la data corrente
    const today = new Date();

    // Calcola il giorno dell'anno (1-366)
    const start = new Date(today.getFullYear(), 0, 0);
    const diff = today - start;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);

    // Assegna un vangelo basato sul giorno dell'anno
    const gospelIndex = (dayOfYear - 1) % gospels.length;
    setGospel(gospels[gospelIndex]);

    // Formatta la data in italiano con giorno e mese capitalizzati
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formatted = today.toLocaleDateString('it-IT', options);
    // Capitalizza la prima lettera
    const capitalized = formatted.charAt(0).toUpperCase() + formatted.slice(1);
    setFormattedDate(capitalized);
  }, []);

  return (
    <div className="welcome-container">
      {/* Header con Logo */}
      <header className="welcome-header">
        <img src="/images/tuoLogo.png" alt="Logo" className="welcome-logo" />
      </header>
      
      {/* Main Content */}
      <main className="welcome-main">
        <h1 className="welcome-title">Diario di Bordo</h1>
        <div className="welcome-date-container">
          <FaCalendarAlt className="date-icon" />
          <p className="welcome-date">{formattedDate}</p>
        </div>
        <p className="welcome-subtitle">Accedi o registrati</p>
        
        {/* Pulsanti di Accesso */}
        <div className="welcome-buttons">
          <Link to="/login" className="welcome-button login-button">
            Accedi
          </Link>
          <Link to="/signup" className="welcome-button signup-button">
            Registrati
          </Link>
          <Link to="/resetpassword" className="welcome-button reset-button">
            Recupera Password
          </Link>
        </div>
        
        {/* Vangelo del Giorno */}
        <div className="gospel-section">
          <h2 className="gospel-title">Vangelo del Giorno</h2>
          <p className="gospel-passage">{gospel.passage}</p>
        </div>
      </main>
      
      {/* Footer con Icone Social */}
      <footer className="welcome-footer">
        <div className="social-icons">
          <a href="https://www.tuosito.com" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="Sito Internet">
            <FaGlobe />
          </a>
          <a href="https://www.facebook.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="Facebook">
            <FaFacebookF />
          </a>
          <a href="https://wa.me/tuonumber" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="WhatsApp">
            <FaWhatsapp />
          </a>
          <a href="https://www.instagram.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="Instagram">
            <FaInstagram />
          </a>
          <a href="https://www.pinterest.com/tuoprofilo" target="_blank" rel="noopener noreferrer" className="social-icon" aria-label="Pinterest">
            <FaPinterestP />
          </a>
        </div>
        <p className="footer-text">&copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore - Monserrato</p>
      </footer>
    </div>
  );
}

export default Welcome;
