// src/components/ProtectedRoute.js

import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Navigate } from 'react-router-dom';

function ProtectedRoute({ children, adminOnly = false }) {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState('loading');
  const [role, setRole] = useState('');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setStatus(userData.status);
          setRole(userData.role);
        } else {
          setStatus('no-document');
        }
      } else {
        setUser(null);
        setStatus('no-user');
      }
    });

    return () => unsubscribe();
  }, []);

  if (status === 'loading') {
    return <div>Caricamento...</div>;
  }

  if (status === 'approved') {
    if (adminOnly && role !== 'admin') {
      return <Navigate to="/dashboard" replace />;
    }
    return children;
  }

  if (status === 'pending') {
    return <Navigate to="/login" replace />;
  }

  if (status === 'rejected') {
    return <Navigate to="/login" replace />;
  }

  return <Navigate to="/login" replace />;
}

export default ProtectedRoute;
