// src/components/CatechistDetails.js

import React from 'react';
import {
  Typography,
  Button,
  Modal,
  Box,
  Avatar,
  Grid,
} from '@mui/material';
import '../styles/Details.css';

function CatechistDetails({ catechist, open, onClose }) {
  if (!catechist) return null;

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="catechist-details-modal">
      <Box className="details-modal">
        <Box className="details-content">
          <Grid container spacing={2}>
            <Grid item xs={12} className="photo-container">
              <Avatar
                src={catechist.photoURL || '/icons/default-avatar.png'}
                alt={`${catechist.nome} ${catechist.cognome}`}
                className="details-avatar"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" className="student-name">
                {catechist.nome} {catechist.cognome}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" className="student-detail">
                <strong>Email:</strong> {catechist.email}
              </Typography>
              <Typography variant="body1" className="student-detail">
                <strong>Indirizzo:</strong> {catechist.indirizzo || 'N/A'}
              </Typography>
              <Typography variant="body1" className="student-detail">
                <strong>Telefono:</strong> {catechist.telefono || 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} className="button-container">
              <Button
                variant="contained"
                color="primary"
                onClick={onClose}
                className="close-button"
              >
                Chiudi
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
}

export default CatechistDetails;
