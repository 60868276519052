// src/pages/CalendarPage.js

import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import {
  Modal,
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  AppBar,
  Toolbar,
  Container,
  Paper,
  Tooltip,
  Badge,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Close as CloseIcon } from '@mui/icons-material';
import Sidebar from '../components/Sidebar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../styles/CalendarPage.css';
import 'moment/locale/it';
import { RRule } from 'rrule';
import {
  collection,
  addDoc,
  onSnapshot,
  doc,
  updateDoc,
  deleteDoc,
  query,
  where,
} from 'firebase/firestore';
import {
  FaGlobe,
  FaFacebookF,
  FaWhatsapp,
  FaInstagram,
  FaPinterestP,
  FaBars,
  FaHome,
  FaBook,
} from 'react-icons/fa';
import { db, auth } from '../firebase';
import { useNavigate } from 'react-router-dom';

const localizer = momentLocalizer(moment);

function CalendarPage() {
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [eventTitle, setEventTitle] = useState('');
  const [eventStart, setEventStart] = useState(null);
  const [eventEnd, setEventEnd] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [userId, setUserId] = useState(null);
  const [recurrence, setRecurrence] = useState('none');
  const [category, setCategory] = useState('');
  const [categories] = useState([
    { name: 'Parrocchia', color: '#2196F3' },
    { name: 'Catechismo', color: '#4CAF50' },
    { name: 'Eventi', color: '#FF9800' },
    { name: 'Altro', color: '#9C27B0' },
    { name: 'Compleanno', color: '#FFEB3B' },
  ]);

  // Stato per la Sidebar
  const [isOpenSidebar, setIsOpenSidebar] = useState(false);

  // Stati per le notifiche
  const [upcomingEventsCount, setUpcomingEventsCount] = useState(0);
  const [birthdayNotifications, setBirthdayNotifications] = useState(0);

  const navigate = useNavigate();

  // Imposta la localizzazione in italiano
  moment.locale('it');

  // Helper function per mappare la ricorrenza selezionata a stringa RRule
  const getRRuleString = (recurrence) => {
    switch (recurrence) {
      case 'daily':
        return 'FREQ=DAILY';
      case 'weekly':
        return 'FREQ=WEEKLY';
      case 'monthly':
        return 'FREQ=MONTHLY';
      case 'yearly':
        return 'FREQ=YEARLY';
      default:
        return null;
    }
  };

  // Helper function per convertire stringa RRule a 'daily', 'weekly', etc.
  const getRecurrenceFromRRule = (recurrenceStr) => {
    if (!recurrenceStr || recurrenceStr === 'none') return 'none';
    const freqMatch = recurrenceStr.match(/FREQ=(\w+);?/);
    if (freqMatch && freqMatch[1]) {
      switch (freqMatch[1]) {
        case 'DAILY':
          return 'daily';
        case 'WEEKLY':
          return 'weekly';
        case 'MONTHLY':
          return 'monthly';
        case 'YEARLY':
          return 'yearly';
        default:
          return 'none';
      }
    }
    return 'none';
  };

  // Recupera l'ID dell'utente autenticato e carica gli eventi
  useEffect(() => {
    const unsubscribeAuth = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        // Recupera gli eventi dal database
        const eventsCollection = collection(db, 'events');
        const q = query(eventsCollection, where('userId', '==', user.uid));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          const eventsData = snapshot.docs.map((doc) => ({
            id: doc.id,
            title: doc.data().title,
            start: doc.data().start.toDate(),
            end: doc.data().end.toDate(),
            recurrence: doc.data().recurrence,
            category: doc.data().category,
          }));
          setEvents(eventsData);
        });
        return () => unsubscribe();
      } else {
        navigate('/login');
      }
    });
    return () => unsubscribeAuth();
  }, [navigate]);

  // Calcolo delle notifiche
  useEffect(() => {
    if (!events.length) {
      setUpcomingEventsCount(0);
      setBirthdayNotifications(0);
      return;
    }

    let upcomingEvents = 0;
    let birthdayEvents = 0;

    const now = moment().startOf('day');
    const endDate = moment().add(1, 'year').endOf('day'); // Limita l'intervallo a 1 anno

    events.forEach((event) => {
      let eventOccurrences = [];

      if (event.recurrence && event.recurrence !== 'none') {
        try {
          const ruleOptions = {
            ...RRule.parseString(event.recurrence),
            dtstart: event.start,
            until: endDate.toDate(),
          };

          // Se l'evento è un compleanno, impostiamo bymonth e bymonthday
          if (event.category === 'Compleanno') {
            ruleOptions.freq = RRule.YEARLY;
            ruleOptions.bymonth = moment(event.start).month() + 1; // I mesi in moment() vanno da 0 a 11
            ruleOptions.bymonthday = moment(event.start).date();
            ruleOptions.dtstart = now.toDate(); // Inizia a calcolare da oggi
          }

          const rule = new RRule(ruleOptions);
          const occurrences = rule.between(now.toDate(), endDate.toDate(), true);
          eventOccurrences = occurrences;
        } catch (error) {
          console.error(
            `Errore nel parsing della ricorrenza per l'evento "${event.title}":`,
            error
          );
        }
      } else {
        if (
          moment(event.start).isSameOrAfter(now) &&
          moment(event.start).isSameOrBefore(endDate)
        ) {
          eventOccurrences.push(event.start);
        }
      }

      if (eventOccurrences.length > 0) {
        if (event.category === 'Compleanno') {
          birthdayEvents += eventOccurrences.length;
        } else {
          upcomingEvents += eventOccurrences.length;
        }
      }
    });

    setBirthdayNotifications(birthdayEvents);
    setUpcomingEventsCount(upcomingEvents);
  }, [events]);

  const handleSelectSlot = (slotInfo) => {
    setEventTitle('');
    setEventStart(moment(slotInfo.start));
    setEventEnd(moment(slotInfo.end));
    setRecurrence('none');
    setCategory('');
    setModalOpen(true);
  };

  const handleAddEvent = async () => {
    if (eventTitle && eventStart && eventEnd && userId) {
      const recurrenceStr = getRRuleString(recurrence);
      try {
        await addDoc(collection(db, 'events'), {
          userId: userId,
          title: eventTitle,
          start: eventStart.toDate(),
          end: eventEnd.toDate(),
          recurrence: recurrenceStr || 'none',
          category: category,
        });
        console.log(`Evento "${eventTitle}" aggiunto con successo.`);
        // Resetta gli stati
        setEventTitle('');
        setEventStart(null);
        setEventEnd(null);
        setRecurrence('none');
        setCategory('');
        setModalOpen(false);
      } catch (error) {
        console.error("Errore durante l'aggiunta dell'evento:", error);
      }
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    setEventTitle(event.title);
    setEventStart(moment(event.start));
    setEventEnd(moment(event.end));
    setRecurrence(getRecurrenceFromRRule(event.recurrence));
    setCategory(event.category || '');
    setEditModalOpen(true);
  };

  const handleEditEvent = async () => {
    if (eventTitle && eventStart && eventEnd && selectedEvent) {
      const recurrenceStr = getRRuleString(recurrence);
      try {
        const eventRef = doc(db, 'events', selectedEvent.id);
        await updateDoc(eventRef, {
          title: eventTitle,
          start: eventStart.toDate(),
          end: eventEnd.toDate(),
          recurrence: recurrenceStr || 'none',
          category: category,
        });
        console.log(`Evento "${eventTitle}" aggiornato con successo.`);
        // Resetta gli stati
        setSelectedEvent(null);
        setEventTitle('');
        setEventStart(null);
        setEventEnd(null);
        setRecurrence('none');
        setCategory('');
        setEditModalOpen(false);
      } catch (error) {
        console.error("Errore durante la modifica dell'evento:", error);
      }
    }
  };

  const handleDeleteEvent = async () => {
    if (selectedEvent) {
      try {
        const eventRef = doc(db, 'events', selectedEvent.id);
        await deleteDoc(eventRef);
        console.log(`Evento "${selectedEvent.title}" eliminato con successo.`);
        setSelectedEvent(null);
        setDeleteModalOpen(false);
        setEditModalOpen(false);
      } catch (error) {
        console.error("Errore durante l'eliminazione dell'evento:", error);
      }
    }
  };

  // Funzione per ottenere gli eventi imminenti
  const getUpcomingEvents = () => {
    const now = moment().startOf('day');
    const endDate = moment().add(1, 'year').endOf('day');

    const upcoming = [];

    events.forEach((event) => {
      if (event.recurrence && event.recurrence !== 'none') {
        try {
          const ruleOptions = {
            ...RRule.parseString(event.recurrence),
            dtstart: event.start,
            until: endDate.toDate(),
          };

          // Se l'evento è un compleanno, impostiamo bymonth e bymonthday
          if (event.category === 'Compleanno') {
            ruleOptions.freq = RRule.YEARLY;
            ruleOptions.bymonth = moment(event.start).month() + 1;
            ruleOptions.bymonthday = moment(event.start).date();
            ruleOptions.dtstart = now.toDate();
          }

          const rule = new RRule(ruleOptions);
          const occurrences = rule.between(now.toDate(), endDate.toDate(), true);
          occurrences.forEach((occurrence) => {
            upcoming.push({
              ...event,
              start: new Date(occurrence),
              end: new Date(occurrence.getTime() + (event.end - event.start)),
            });
          });
        } catch (error) {
          console.error(
            `Errore nel parsing della ricorrenza per l'evento "${event.title}":`,
            error
          );
        }
      } else {
        if (
          moment(event.start).isSameOrAfter(now) &&
          moment(event.start).isSameOrBefore(endDate)
        ) {
          upcoming.push(event);
        }
      }
    });

    return upcoming.sort((a, b) => a.start - b.start);
  };

  // Stili per gli eventi in base alla categoria
  const eventStyleGetter = (event) => {
    const categoryObj = categories.find((cat) => cat.name === event.category);
    const backgroundColor = categoryObj ? categoryObj.color : '#3174ad';
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: '5px',
      opacity: 0.8,
      color: event.category === 'Compleanno' ? '#000' : 'white', // Testo nero per compleanni
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  const currentDateFormatted = moment().format('dddd, D MMMM YYYY');

  // Funzione per navigare alla Dashboard
  const navigateToDashboard = () => {
    navigate('/Dashboard');
    setIsOpenSidebar(false); // Chiude la Sidebar dopo la navigazione
  };

  // Funzione per navigare alla Libreria
  const navigateToLibrary = () => {
    navigate('/Library');
    setIsOpenSidebar(false); // Chiude la Sidebar dopo la navigazione
  };

  return (
    <div className="calendar-container">
      <Sidebar
        isOpen={isOpenSidebar}
        toggleSidebar={() => setIsOpenSidebar(!isOpenSidebar)}
      />
      <div className="calendar-content">
        {/* AppBar */}
        <AppBar position="fixed" color="primary" className="app-bar">
          <Toolbar>
            {/* Pulsante per aprire la Sidebar */}
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setIsOpenSidebar(!isOpenSidebar)}
              className="menu-button"
            >
              <FaBars />
            </IconButton>
            <Typography variant="h6" className="app-title">
              Calendario
            </Typography>
            <div style={{ flexGrow: 1 }} />

            {/* Contenitore per gli elementi a destra con maggiore spazio */}
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              {/* Contenitore per la Data */}
              <Typography variant="subtitle1" className="current-date">
                {currentDateFormatted}
              </Typography>

              {/* Icona della Casa con Badge */}
              <Tooltip title="Dashboard">
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="dashboard"
                  onClick={navigateToDashboard}
                  className="calendar-icon-button"
                >
                  <Badge
                    badgeContent={upcomingEventsCount}
                    color="error"
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    sx={{
                      '& .MuiBadge-badge': {
                        backgroundColor: '#f44336',
                        color: 'white',
                        fontSize: '0.7rem',
                        height: '16px',
                        minWidth: '16px',
                        borderRadius: '8px',
                        transform: 'scale(1) translate(50%, -50%)',
                      },
                    }}
                  >
                    <Badge
                      badgeContent={birthdayNotifications}
                      color="warning"
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      sx={{
                        '& .MuiBadge-badge': {
                          backgroundColor: '#ffeb3b',
                          color: '#000',
                          fontSize: '0.7rem',
                          height: '16px',
                          minWidth: '16px',
                          borderRadius: '8px',
                          transform: 'scale(1) translate(50%, 50%)',
                        },
                      }}
                    >
                      <FaHome />
                    </Badge>
                  </Badge>
                </IconButton>
              </Tooltip>

              {/* Icona del Libro */}
              <Tooltip title="Libreria">
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="libreria"
                  onClick={navigateToLibrary}
                  className="library-icon-button"
                >
                  <FaBook />
                </IconButton>
              </Tooltip>
            </div>
          </Toolbar>
        </AppBar>
        <Toolbar /> {/* Spazio per compensare l'AppBar fissa */}

        {/* Contenuto principale */}
        <Container maxWidth="lg" className="calendar-container-content">
          <Grid container spacing={4}>
            {/* Campo di ricerca */}
            <Grid item xs={12}>
              <TextField
                label="Cerca Appuntamenti"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
                margin="normal"
                variant="outlined"
              />
            </Grid>

            {/* Calendario */}
            <Grid item xs={12}>
              <Paper className="paper">
                <Calendar
                  localizer={localizer}
                  events={events.filter((event) =>
                    event.title.toLowerCase().includes(searchQuery.toLowerCase())
                  )}
                  startAccessor="start"
                  endAccessor="end"
                  style={{ height: 600 }}
                  selectable
                  onSelectSlot={handleSelectSlot}
                  onSelectEvent={handleSelectEvent}
                  eventPropGetter={eventStyleGetter}
                  messages={{
                    next: 'Successivo',
                    previous: 'Precedente',
                    today: 'Oggi',
                    month: 'Mese',
                    week: 'Settimana',
                    day: 'Giorno',
                    agenda: 'Agenda',
                    date: 'Data',
                    time: 'Ora',
                    event: 'Evento',
                    allDay: 'Tutto il giorno',
                    noEventsInRange: 'Nessun evento in questo intervallo.',
                    showMore: (total) => `+ Altri (${total})`,
                  }}
                />
              </Paper>
            </Grid>

            {/* Sezione per gli eventi imminenti */}
            <Grid item xs={12}>
              <Paper className="paper">
                <Typography variant="h6" gutterBottom>
                  Prossimi Appuntamenti
                </Typography>
                <List>
                  {getUpcomingEvents().map((event, index) => (
                    <ListItem key={index}>
                      <ListItemText
                        primary={event.title}
                        secondary={moment(event.start).format(
                          'dddd, D MMMM YYYY, HH:mm'
                        )}
                      />
                    </ListItem>
                  ))}
                  {getUpcomingEvents().length === 0 && (
                    <Typography>Nessun appuntamento imminente.</Typography>
                  )}
                </List>
              </Paper>
            </Grid>
          </Grid>
        </Container>

        {/* Modale per aggiungere eventi */}
        <Modal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          aria-labelledby="add-event-modal-title"
          aria-describedby="add-event-modal-description"
        >
          <Box className="modal-box">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" id="add-event-modal-title">
                      Aggiungi Appuntamento
                    </Typography>
                    <IconButton onClick={() => setModalOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Titolo"
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Data e Ora Inizio"
                    value={eventStart}
                    onChange={(newValue) => setEventStart(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="outlined" required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Data e Ora Fine"
                    value={eventEnd}
                    onChange={(newValue) => setEventEnd(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="outlined" required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="recurrence-label">Ricorrenza</InputLabel>
                    <Select
                      labelId="recurrence-label"
                      label="Ricorrenza"
                      value={recurrence}
                      onChange={(e) => setRecurrence(e.target.value)}
                    >
                      <MenuItem value="none">Nessuna</MenuItem>
                      <MenuItem value="daily">Giornaliera</MenuItem>
                      <MenuItem value="weekly">Settimanale</MenuItem>
                      <MenuItem value="monthly">Mensile</MenuItem>
                      <MenuItem value="yearly">Annuale</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="category-label">Categoria</InputLabel>
                    <Select
                      labelId="category-label"
                      label="Categoria"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {categories.map((cat) => (
                        <MenuItem key={cat.name} value={cat.name}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="flex-end" sx={{ gap: 2 }}>
                    <Button variant="contained" color="primary" onClick={handleAddEvent}>
                      Aggiungi
                    </Button>
                    <Button variant="outlined" onClick={() => setModalOpen(false)}>
                      Annulla
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>
        </Modal>

        {/* Modale per modificare eventi */}
        <Modal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          aria-labelledby="edit-event-modal-title"
          aria-describedby="edit-event-modal-description"
        >
          <Box className="modal-box">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5" id="edit-event-modal-title">
                      Modifica Appuntamento
                    </Typography>
                    <IconButton onClick={() => setEditModalOpen(false)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Titolo"
                    value={eventTitle}
                    onChange={(e) => setEventTitle(e.target.value)}
                    fullWidth
                    variant="outlined"
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Data e Ora Inizio"
                    value={eventStart}
                    onChange={(newValue) => setEventStart(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="outlined" required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DateTimePicker
                    label="Data e Ora Fine"
                    value={eventEnd}
                    onChange={(newValue) => setEventEnd(newValue)}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth variant="outlined" required />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="recurrence-label">Ricorrenza</InputLabel>
                    <Select
                      labelId="recurrence-label"
                      label="Ricorrenza"
                      value={recurrence}
                      onChange={(e) => setRecurrence(e.target.value)}
                    >
                      <MenuItem value="none">Nessuna</MenuItem>
                      <MenuItem value="daily">Giornaliera</MenuItem>
                      <MenuItem value="weekly">Settimanale</MenuItem>
                      <MenuItem value="monthly">Mensile</MenuItem>
                      <MenuItem value="yearly">Annuale</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="category-label">Categoria</InputLabel>
                    <Select
                      labelId="category-label"
                      label="Categoria"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                    >
                      {categories.map((cat) => (
                        <MenuItem key={cat.name} value={cat.name}>
                          {cat.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between">
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setDeleteModalOpen(true)}
                    >
                      Elimina
                    </Button>
                    <Box display="flex" sx={{ gap: 2 }}>
                      <Button variant="contained" color="primary" onClick={handleEditEvent}>
                        Salva
                      </Button>
                      <Button variant="outlined" onClick={() => setEditModalOpen(false)}>
                        Annulla
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Box>
        </Modal>

        {/* Modale per confermare l'eliminazione */}
        <Modal
          open={deleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          aria-labelledby="delete-event-modal-title"
          aria-describedby="delete-event-modal-description"
        >
          <Box className="modal-box">
            <Typography variant="h5" id="delete-event-modal-title">
              Elimina Appuntamento
            </Typography>
            <Typography id="delete-event-modal-description">
              Sei sicuro di voler eliminare questo appuntamento?
            </Typography>
            <Box display="flex" justifyContent="flex-end" marginTop={2} sx={{ gap: 2 }}>
              <Button variant="contained" color="secondary" onClick={handleDeleteEvent}>
                Elimina
              </Button>
              <Button variant="outlined" onClick={() => setDeleteModalOpen(false)}>
                Annulla
              </Button>
            </Box>
          </Box>
        </Modal>

        {/* Footer */}
        <footer className="calendar-footer">
          <div className="social-buttons">
            <a
              href="https://www.tuosito.com"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Sito Internet"
            >
              <FaGlobe />
            </a>
            <a
              href="https://www.facebook.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Facebook"
            >
              <FaFacebookF />
            </a>
            <a
              href="https://wa.me/tuonumber"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="WhatsApp"
            >
              <FaWhatsapp />
            </a>
            <a
              href="https://www.instagram.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              href="https://www.pinterest.com/tuoprofilo"
              target="_blank"
              rel="noopener noreferrer"
              className="social-button"
              aria-label="Pinterest"
            >
              <FaPinterestP />
            </a>
          </div>
          <p className="footer-text">
            &copy; {new Date().getFullYear()} Parrocchia del S.S. Redentore - Monserrato
          </p>
        </footer>
      </div>
    </div>
  );
}

export default CalendarPage;
