// src/utils/generatePdfReport.js

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

/**
 * Utility function to fetch an image from a URL, compress it, and convert it to Base64.
 *
 * @param {string} url - URL dell'immagine.
 * @param {number} maxWidth - Larghezza massima desiderata.
 * @returns {Promise<string|null>} - Base64 string dell'immagine compressa o null se fallisce.
 */
const getCompressedImageBase64 = async (url, maxWidth = 100) => { // Aumentata la larghezza massima per maggiore qualità
  if (!url) return null;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const blob = await response.blob();

    return await new Promise((resolve, reject) => {
      const img = new Image();
      const urlCreator = window.URL || window.webkitURL;
      img.crossOrigin = 'Anonymous'; // Evita problemi CORS
      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        // Calcola le nuove dimensioni mantenendo il rapporto di aspetto
        const scaleFactor = maxWidth / img.width;
        canvas.width = maxWidth;
        canvas.height = img.height * scaleFactor;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Esporta l'immagine compressa come PNG con qualità migliore
        const compressedDataUrl = canvas.toDataURL('image/png', 1.0); // Qualità massima
        const base64String = compressedDataUrl.split(',')[1];
        resolve(base64String);
      };
      img.onerror = () => {
        reject(new Error('Errore nel caricamento dell\'immagine'));
      };
      img.src = urlCreator.createObjectURL(blob);
    });
  } catch (error) {
    console.error('Errore nel caricamento/compressione dell\'immagine:', error);
    return null;
  }
};

/**
 * Funzione per rilevare se l'utente sta utilizzando un dispositivo mobile.
 *
 * @returns {boolean} - Restituisce true se l'utente è su un dispositivo mobile, altrimenti false.
 */
const isMobileDevice = () => {
  return /Mobi|Android/i.test(navigator.userAgent);
};

/**
 * Definizione degli stili comuni per garantire coerenza tra i report.
 */
const commonStyles = {
  margin: 20,
  primaryColor: [41, 128, 185], // Colore blu elegante
  headerFont: 'Helvetica',
  headerFontStyle: 'bold',
  headerFontSize: 18,
  titleFont: 'Helvetica',
  titleFontStyle: 'bold',
  titleFontSize: 16,
  subtitleFont: 'Helvetica',
  subtitleFontStyle: 'normal',
  subtitleFontSize: 12,
  tableTheme: 'striped',
  tableFont: 'Helvetica',
  tableFontSize: 10,
  tableCellPadding: 5, // Aumentato il padding per maggiore altezza
  minRowHeight: 35, // Altezza minima delle righe aumentata a 35
};

/**
 * Funzione per generare un report PDF giornaliero.
 *
 * @param {Array} students - Array di oggetti studente.
 * @param {string} currentDate - Data specifica dell'incontro (formato DD/MM/YYYY).
 * @param {string} lessonPlan - Programma della lezione.
 * @param {string} dashboardTitle - Titolo personalizzato della dashboard.
 * @param {string} catechist - Nome del catechista.
 * @returns {Object} - Contiene il blob del PDF e il nome del file.
 */
export const generatePdfReport = async (
  students,
  currentDate,
  lessonPlan,
  dashboardTitle,
  catechist
) => {
  const doc = new jsPDF();

  const {
    margin,
    primaryColor,
    headerFont,
    headerFontStyle,
    headerFontSize,
    titleFont,
    titleFontStyle,
    titleFontSize,
    subtitleFont,
    subtitleFontStyle,
    subtitleFontSize,
    tableTheme,
    tableFont,
    tableFontSize,
    tableCellPadding,
    minRowHeight,
  } = commonStyles;

  const pageWidth = doc.internal.pageSize.getWidth();
  const usableWidth = pageWidth - 2 * margin;

  // Aggiungi l'intestazione della parrocchia
  doc.setFont(headerFont, headerFontStyle);
  doc.setFontSize(headerFontSize);
  doc.setTextColor(...primaryColor);
  doc.text(
    'Parrocchia del S.S. Redentore - Monserrato',
    pageWidth / 2,
    margin,
    { align: 'center' }
  );

  // Disegna una linea sotto l'intestazione
  doc.setDrawColor(...primaryColor);
  doc.setLineWidth(0.5);
  doc.line(margin, margin + 5, pageWidth - margin, margin + 5);

  // Sposta la posizione verticale
  let yPosition = margin + 15;

  // Aggiungi il titolo della Dashboard centrato
  doc.setFont(titleFont, titleFontStyle);
  doc.setFontSize(titleFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(dashboardTitle, pageWidth / 2, yPosition, { align: 'center' });
  yPosition += 10;

  // Aggiungi il nome del Catechista e la Data della Lezione
  doc.setFont(subtitleFont, subtitleFontStyle);
  doc.setFontSize(subtitleFontSize);
  doc.setTextColor(0, 0, 0);
  const catechistAndDate = `Catechista: ${catechist}    Data dell'incontro: ${currentDate}`;
  doc.text(catechistAndDate, pageWidth / 2, yPosition, { align: 'center' });
  yPosition += 10;

  // Disegna una linea sotto il titolo strutturato
  doc.setDrawColor(...primaryColor);
  doc.setLineWidth(0.5);
  doc.line(margin, yPosition, pageWidth - margin, yPosition);
  yPosition += 10;

  // Aggiungi il programma della lezione
  doc.setFont(titleFont, 'bold');
  doc.setFontSize(14);
  doc.setTextColor(...primaryColor);
  doc.text('Programma svolto:', margin, yPosition);
  yPosition += 7;
  doc.setFont(subtitleFont, 'normal');
  doc.setFontSize(12);
  doc.setTextColor(0, 0, 0);
  const lessonPlanLines = doc.splitTextToSize(lessonPlan, usableWidth);
  doc.text(lessonPlanLines, margin, yPosition, { align: 'left', maxWidth: usableWidth });
  yPosition += lessonPlanLines.length * 7 + 10;

  // Aggiungi la tabella degli studenti
  doc.setFont(titleFont, 'bold');
  doc.setFontSize(14);
  doc.setTextColor(...primaryColor);
  doc.text('Elenco Studenti:', margin, yPosition);
  yPosition += 7;

  // Prepara le intestazioni della tabella
  const dailyTableColumns = [
    { header: 'Foto', dataKey: 'foto' },
    { header: '#', dataKey: 'numero' },
    { header: 'Cognome', dataKey: 'cognome' },
    { header: 'Nome', dataKey: 'nome' },
    { header: 'Data di Nascita', dataKey: 'dataNascita' },
    { header: 'Presenza', dataKey: 'presenza' },
  ];

  // Prepara i dati per la tabella con le immagini
  const dailyTableRows = await Promise.all(
    students.map(async (student, index) => {
      const imageBase64 = student.photoURL
        ? await getCompressedImageBase64(student.photoURL, 100) // Aumentata la larghezza a 100px per maggiore qualità
        : null;
      return {
        foto: imageBase64,
        numero: index + 1,
        cognome: student.cognome,
        nome: student.nome,
        dataNascita:
          student.dataNascita instanceof Timestamp
            ? moment(student.dataNascita.toDate()).format('DD/MM/YYYY')
            : moment(student.dataNascita).format('DD/MM/YYYY'),
        presenza: student.present ? 'Sì' : 'No',
      };
    })
  );

  // Definisci le larghezze fisse delle colonne
  const fixedWidths = {
    foto: 20,
    numero: 15,
    cognome: 30,
    nome: 30,
    dataNascita: 30,
    presenza: 25,
  };
  const totalFixed = Object.values(fixedWidths).reduce((a, b) => a + b, 0);
  const remainingWidth = usableWidth - totalFixed;

  // Calcola il fattore di scala per le colonne fisse
  const scalingFactor = remainingWidth > 0 ? usableWidth / totalFixed : 1;

  const dailyColumnStyles = {
    foto: { cellWidth: fixedWidths.foto * scalingFactor },
    numero: { halign: 'center', cellWidth: fixedWidths.numero * scalingFactor },
    cognome: { halign: 'left', cellWidth: fixedWidths.cognome * scalingFactor },
    nome: { halign: 'left', cellWidth: fixedWidths.nome * scalingFactor },
    dataNascita: { halign: 'center', cellWidth: fixedWidths.dataNascita * scalingFactor },
    presenza: { halign: 'center', cellWidth: fixedWidths.presenza * scalingFactor },
  };

  // Aggiungi la tabella al PDF
  doc.autoTable({
    startY: yPosition,
    columns: dailyTableColumns,
    body: dailyTableRows,
    theme: tableTheme, // Tema moderno
    headStyles: {
      fillColor: primaryColor,
      textColor: 255,
      fontStyle: 'bold',
      halign: 'center',
      valign: 'middle',
    },
    styles: {
      font: tableFont,
      fontSize: tableFontSize,
      cellPadding: tableCellPadding,
      valign: 'middle',
      halign: 'center',
      overflow: 'linebreak',
      textOverflow: 'ellipses',
      minRowHeight: minRowHeight, // Altezza minima delle righe aumentata a 35
      lineHeight: 1.2, // Aggiunto lineHeight per maggiore spazio verticale
    },
    columnStyles: dailyColumnStyles,
    margin: { left: margin, right: margin },
    didParseCell: (data) => {
      if (data.column.dataKey === 'foto') {
        data.cell.text = ''; // Rimuove il testo del Base64
      }
    },
    didDrawCell: (data) => {
      if (data.column.dataKey === 'foto' && data.cell.section === 'body') {
        const img = data.cell.raw;
        if (img) {
          // Inserisci l'immagine con dimensioni ridotte e centrata
          try {
            const imgWidth = 10;
            const imgHeight = 10;
            const imgX = data.cell.x + (data.cell.width - imgWidth) / 2;
            const imgY = data.cell.y + (data.cell.height - imgHeight) / 2;
            doc.addImage(img, 'PNG', imgX, imgY, imgWidth, imgHeight); // Formato PNG

            // Disegna un cerchio attorno all'immagine per renderla tonda
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.3);
            doc.circle(imgX + imgWidth / 2, imgY + imgHeight / 2, imgWidth / 2);
          } catch (error) {
            console.error('Errore nell\'aggiunta dell\'immagine:', error);
          }
        } else {
          // Se non c'è immagine, mostra la prima lettera del nome
          const firstLetter = data.row.raw.nome.charAt(0).toUpperCase();
          // Disegna un cerchio pieno
          doc.setFillColor(...primaryColor);
          doc.setTextColor(255, 255, 255);
          doc.setFont('Helvetica', 'bold');
          doc.circle(data.cell.x + data.cell.width / 2, data.cell.y + data.cell.height / 2, 5, 'F'); // Disegna cerchio pieno
          // Inserisci la lettera centrata nel cerchio
          doc.text(firstLetter, data.cell.x + data.cell.width / 2, data.cell.y + data.cell.height / 2 + 1, { align: 'center', baseline: 'middle' });
        }
      }
    },
    // Gestisce i salti di pagina automaticamente
    pageBreak: 'auto',
  });

  // Genera un nome file sicuro sostituendo eventuali caratteri problematici
  const safeCatechist = catechist.replace(/[^a-zA-Z0-9]/g, '_');
  const safeDate = currentDate.replace(/\//g, '-').replace(/[^a-zA-Z0-9-]/g, '_');
  const fileName = `Report_${safeCatechist}_${safeDate}.pdf`;

  // Salva il PDF sul dispositivo dell'utente
  doc.save(fileName);

  // Genera il blob del PDF per l'upload su Google Drive
  const pdfBlob = doc.output('blob');

  // *** Nuova Funzionalità: Apri il PDF in una nuova pagina se su mobile ***
  if (isMobileDevice()) {
    // Crea un URL oggetto dal blob
    const pdfUrl = URL.createObjectURL(pdfBlob);
    // Apre il PDF in una nuova scheda/finestra
    window.open(pdfUrl, '_blank');
    // Opzionale: revoca l'URL dopo un certo tempo per liberare risorse
    setTimeout(() => {
      URL.revokeObjectURL(pdfUrl);
    }, 1000 * 60); // Revoca dopo 1 minuto
  }
  // *** Fine Nuova Funzionalità ***

  // Restituisce pdfBlob e fileName per l'upload
  return { pdfBlob, fileName };
};

/**
 * Funzione per generare un report PDF mensile.
 *
 * @param {string} dashboardTitle - Titolo personalizzato della dashboard.
 * @param {string} catechists - Nome dei catechisti.
 * @param {string} additionalTitle - Titolo aggiuntivo con il mese e l'anno.
 * @param {Array} monthlyPrograms - Array di programmi del mese.
 * @param {Array} attendanceData - Array di oggetti contenenti le presenze degli studenti.
 * @returns {Object} - Contiene il blob del PDF e il nome del file.
 */
export const generateMonthlyPdfReport = async (
  dashboardTitle,
  catechists,
  additionalTitle,
  monthlyPrograms,
  attendanceData
) => {
  const doc = new jsPDF();

  const {
    margin,
    primaryColor,
    headerFont,
    headerFontStyle,
    headerFontSize,
    titleFont,
    titleFontStyle,
    titleFontSize,
    subtitleFont,
    tableTheme,
    tableFont,
    tableFontSize,
    tableCellPadding,
    minRowHeight,
  } = commonStyles;

  const pageWidth = doc.internal.pageSize.getWidth();
  const usableWidth = pageWidth - 2 * margin;

  // Aggiungi l'intestazione della parrocchia
  doc.setFont(headerFont, headerFontStyle);
  doc.setFontSize(headerFontSize);
  doc.setTextColor(...primaryColor);
  doc.text(
    'Parrocchia del S.S. Redentore - Monserrato',
    pageWidth / 2,
    margin,
    { align: 'center' }
  );

  // Disegna una linea sotto l'intestazione
  doc.setDrawColor(...primaryColor);
  doc.setLineWidth(0.5);
  doc.line(margin, margin + 5, pageWidth - margin, margin + 5);

  // Sposta la posizione verticale
  let yPosition = margin + 15;

  // Aggiungi il titolo della Dashboard centrato
  doc.setFont(titleFont, titleFontStyle);
  doc.setFontSize(titleFontSize);
  doc.setTextColor(0, 0, 0);
  doc.text(dashboardTitle, pageWidth / 2, yPosition, { align: 'center' });
  yPosition += 10;

  // Aggiungi il titolo aggiuntivo (Report Mensile) e il nome del Catechista
  doc.setFont(titleFont, 'bold');
  doc.setFontSize(14);
  doc.setTextColor(0, 0, 0);
  const fullAdditionalTitle = `${additionalTitle} - Catechista: ${catechists}`;
  doc.text(fullAdditionalTitle, pageWidth / 2, yPosition, { align: 'center' }); // Includi il catechista nel titolo
  yPosition += 7;

  // Disegna una linea sotto il titolo aggiuntivo
  doc.setDrawColor(...primaryColor);
  doc.setLineWidth(0.5);
  doc.line(margin, yPosition, pageWidth - margin, yPosition);
  yPosition += 10;

  // Ordina i monthlyPrograms dalla data più vecchia alla più recente
  const sortedMonthlyPrograms = [...monthlyPrograms].sort((a, b) => {
    const dateA = new Date(a.meetingDate);
    const dateB = new Date(b.meetingDate);
    return dateA - dateB;
  });

  // Aggiungi l'elenco dei programmi del mese con testo giustificato
  doc.setFont(titleFont, 'bold');
  doc.setFontSize(14);
  doc.setTextColor(...primaryColor);
  doc.text('Programmi del Mese:', margin, yPosition);
  yPosition += 7;

  doc.setFont(subtitleFont, 'normal');
  doc.setFontSize(12);
  sortedMonthlyPrograms.forEach((program, index) => {
    const programDate = moment(program.meetingDate).format('DD/MM/YYYY');
    const lessonPlanText = `${index + 1}. ${programDate}: ${program.lessonPlan}`;
    const lessonPlanLines = doc.splitTextToSize(lessonPlanText, usableWidth);
    // Allineamento a sinistra con suddivisione ottimale delle righe
    doc.text(lessonPlanLines, margin, yPosition, { align: 'left', maxWidth: usableWidth });
    yPosition += lessonPlanLines.length * 7 + 3; // Aggiungi più spazio per le linee multiple
  });

  yPosition += 5; // Spazio extra dopo l'elenco dei programmi

  // Aggiungi la tabella delle presenze
  doc.setFont(titleFont, 'bold');
  doc.setFontSize(12);
  doc.setTextColor(...primaryColor);
  doc.text('Presenze Studenti:', margin, yPosition);
  yPosition += 7;

  // Prepara le intestazioni della tabella con titoli abbreviati per le colonne di presenza
  const monthlyTableColumns = [
    { header: 'Foto', dataKey: 'foto' },
    { header: '#', dataKey: 'numero' },
    { header: 'Cognome', dataKey: 'cognome' },
    { header: 'Nome', dataKey: 'nome' },
    ...sortedMonthlyPrograms.map((_, idx) => ({
      header: 'P', // Titolo abbreviato
      dataKey: `presenza_${idx + 1}`,
    })),
    { header: 'Totale', dataKey: 'totale' },
  ];

  // Prepara i dati per la tabella con le immagini
  const monthlyTableRows = await Promise.all(
    attendanceData.map(async (student, index) => {
      const imageBase64 = student.photoURL
        ? await getCompressedImageBase64(student.photoURL, 100) // Aumentata la larghezza a 100px per maggiore qualità
        : null;
      return {
        foto: imageBase64,
        numero: index + 1,
        cognome: student.cognome,
        nome: student.nome,
        ...sortedMonthlyPrograms.reduce((acc, _, idx) => {
          acc[`presenza_${idx + 1}`] = student.presenze[idx] ? 'Sì' : 'No';
          return acc;
        }, {}),
        totale: student.totalPresences,
      };
    })
  );

  // Calcola la larghezza disponibile per le colonne di presenza dinamicamente
  const fixedWidthMonthly = 20 + 15 + 30 + 28 + 20; // Foto + # + Cognome + Nome + Totale Presenze
  const presenceColumnCountMonthly = sortedMonthlyPrograms.length;
  const presenceColumnWidthMonthly = (usableWidth - fixedWidthMonthly) / presenceColumnCountMonthly;

  // Definisci gli stili delle colonne specifici per il report mensile
  const monthlyColumnStyles = {
    foto: { cellWidth: 17 },
    numero: { halign: 'center', cellWidth: 14 },
    cognome: { halign: 'left', cellWidth: 25 },
    nome: { halign: 'left', cellWidth: 28 },
    totale: { halign: 'center', cellWidth: 20 },
  };

  // Aggiungi le colonne di presenza dinamicamente con larghezza adattata
  sortedMonthlyPrograms.forEach((_, idx) => {
    monthlyColumnStyles[`presenza_${idx + 1}`] = {
      halign: 'center',
      cellWidth: presenceColumnWidthMonthly > 18 ? 18 : presenceColumnWidthMonthly, // Imposta un minimo di 18 se necessario
    };
  });

  // Aggiungi la tabella al PDF
  doc.autoTable({
    startY: yPosition,
    columns: monthlyTableColumns,
    body: monthlyTableRows,
    theme: tableTheme, // Tema moderno
    headStyles: {
      fillColor: primaryColor,
      textColor: 255,
      fontStyle: 'bold',
      halign: 'center',
      valign: 'middle',
    },
    styles: {
      font: tableFont,
      fontSize: tableFontSize,
      cellPadding: tableCellPadding,
      valign: 'middle',
      halign: 'center',
      overflow: 'linebreak',
      textOverflow: 'ellipses',
      minRowHeight: minRowHeight, // Altezza minima delle righe aumentata a 35
      lineHeight: 1.2, // Aggiunto lineHeight per maggiore spazio verticale
    },
    columnStyles: monthlyColumnStyles,
    margin: { left: margin, right: margin },
    didParseCell: (data) => {
      // Rimuove il testo nella colonna "Foto" poiché l'immagine verrà disegnata separatamente
      if (data.column.dataKey === 'foto') {
        data.cell.text = 'Foto'; // Assicura che il titolo della colonna sia "Foto"
      }

      // Corregge l'allineamento verticale del simbolo "#"
      if (data.column.dataKey === 'numero' && data.section === 'head') {
        data.cell.styles.valign = 'middle';
      }

      // Gestisce i titoli abbreviati per le colonne di presenza
      if (data.section === 'head' && data.column.dataKey.startsWith('presenza_')) {
        data.cell.styles.valign = 'middle';
        // Assicurati che il testo sia centrato verticalmente
      }
    },
    didDrawCell: (data) => {
      // Disegna le immagini nelle celle della colonna "Foto"
      if (data.column.dataKey === 'foto' && data.cell.section === 'body') {
        const img = data.cell.raw;
        if (img) {
          // Inserisci l'immagine con dimensioni ridotte e centrata
          try {
            const imgWidth = 10;
            const imgHeight = 10;
            const imgX = data.cell.x + (data.cell.width - imgWidth) / 2;
            const imgY = data.cell.y + (data.cell.height - imgHeight) / 2;
            doc.addImage(img, 'PNG', imgX, imgY, imgWidth, imgHeight); // Formato PNG

            // Disegna un cerchio attorno all'immagine per renderla tonda
            doc.setDrawColor(0, 0, 0);
            doc.setLineWidth(0.3);
            doc.circle(imgX + imgWidth / 2, imgY + imgHeight / 2, imgWidth / 2);
          } catch (error) {
            console.error('Errore nell\'aggiunta dell\'immagine:', error);
          }
        } else {
          // Se non c'è immagine, mostra la prima lettera del nome
          const firstLetter = data.row.raw.nome.charAt(0).toUpperCase();
          // Disegna un cerchio pieno
          doc.setFillColor(...primaryColor);
          doc.setTextColor(255, 255, 255);
          doc.setFont('Helvetica', 'bold');
          doc.circle(data.cell.x + data.cell.width / 2, data.cell.y + data.cell.height / 2, 5, 'F'); // Disegna cerchio pieno
          // Inserisci la lettera centrata nel cerchio
          doc.text(firstLetter, data.cell.x + data.cell.width / 2, data.cell.y + data.cell.height / 2 + 1, { align: 'center', baseline: 'middle' });
        }
      }
    },
    // Gestisce i salti di pagina automaticamente
    pageBreak: 'auto',
  });

  // Genera un nome file sicuro sostituendo eventuali caratteri problematici
  const safeCatechists = catechists.replace(/[^a-zA-Z0-9]/g, '_');
  const safeDate = additionalTitle.replace(/\//g, '-').replace(/[^a-zA-Z0-9-]/g, '_');
  const fileName = `Report_${safeCatechists}_${safeDate}.pdf`;

  // Salva il PDF sul dispositivo dell'utente
  doc.save(fileName);

  // Genera il blob del PDF per l'upload su Google Drive
  const pdfBlob = doc.output('blob');

  // *** Nuova Funzionalità: Apri il PDF in una nuova pagina se su mobile ***
  if (isMobileDevice()) {
    // Crea un URL oggetto dal blob
    const pdfUrl = URL.createObjectURL(pdfBlob);
    // Apre il PDF in una nuova scheda/finestra
    window.open(pdfUrl, '_blank');
    // Opzionale: revoca l'URL dopo un certo tempo per liberare risorse
    setTimeout(() => {
      URL.revokeObjectURL(pdfUrl);
    }, 1000 * 60); // Revoca dopo 1 minuto
  }
  // *** Fine Nuova Funzionalità ***

  // Restituisce pdfBlob e fileName per l'upload
  return { pdfBlob, fileName };
};
